import axios from 'axios';
import { API_BASE_URL } from './'; // Vérifiez que ce chemin est correct

// Récupération du token depuis le localStorage
export const token = localStorage.getItem('token');

// Paiement via FedaPay (Mobile Money ou autres)
export const initiateFedaPayPayment = async (amount, courseIds, paymentMethod) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/initiate/payment`,
      {
        amount,
        courseIds,
        paymentMethod,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la création de la transaction:', error);
    throw error;
  }
};

// Paiement par carte avec Stripe
export const confirmStripePayment = async (stripe, elements, total) => {
  try {
    // Créer un PaymentIntent sur le backend
    const response = await axios.post(
      `${API_BASE_URL}/create-payment-intent`,
      { amount: total },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const { clientSecret } = response.data;

    // Confirmer le paiement avec Stripe
    const { error } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement('card'),
      },
    });

    if (error) {
      throw new Error(error.message);
    }
    return 'Paiement réussi';
  } catch (error) {
    console.error('Erreur de paiement Stripe:', error);
    throw error;
  }
};

// Récupération des méthodes de paiement disponibles
export const getPaymentMethods = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/payment-methods`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la récupération des méthodes de paiement:', error);
    throw error;
  }
};

export const updateCheckoutStatus = async ( checkoutId, status) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/checkout/status`,
      {
        checkoutId,
        status,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        });
    return response.data;
  }
  catch (error) {
    console.error('Erreur lors de la mise à jour du statut du paiement:', error);
  }
  };
