import React, { useState } from 'react';
import contactService from '../../services/contactService';
import { toast } from 'react-toastify';

const ContactMain = () => {
  const [formData, setFormData] = useState({
    nom: '',
    prenom: '',
    email: '',
    telephone: '',
    message: '',
  });


  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await contactService.sendContactMessage(formData);
      toast.success('Message envoyé avec succès.');
     
      setFormData({
        nom: '',
        prenom: '',
        email: '',
        telephone: '',
        message: '',
      });
    } catch (error) {
      toast.error('Une erreur est survenue, veuillez réessayer.');
     
    }
  };

  return (
    <div className="pt-100 pb-100">
      {/* Conteneur principal avec largeur à 75% de l'écran */}
      <div className="mx-auto px-4 sm:px-6 lg:px-10" style={{ maxWidth: '75%', width: '100%' }}>
        {/* Titre */}
        <h2 className="text-3xl font-bold mb-60 text-left w-full md:w-[60%]">
          Laissez-nous un message
        </h2>

        <div className="flex flex-col md:flex-row justify-between items-start gap-10">
          {/* Formulaire de contact */}
          <div className="w-full md:w-[55%]">
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div className="flex flex-col sm:flex-row gap-6">
                <div className="w-full">
                  <label htmlFor="nom" className="block mb-2">Nom</label>
                  <input
                    id="nom"
                    className="border border-gray-300 w-full p-2"
                    type="text"
                    value={formData.nom}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="w-full">
                  <label htmlFor="prenom" className="block mb-2">Prénom(s)</label>
                  <input
                    id="prenom"
                    className="border border-gray-300 w-full p-2"
                    type="text"
                    value={formData.prenom}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="flex flex-col sm:flex-row gap-6">
                <div className="w-full">
                  <label htmlFor="email" className="block mb-2">Adresse mail</label>
                  <input
                    id="email"
                    className="border border-gray-300 w-full p-2"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="w-full">
                  <label htmlFor="telephone" className="block mb-2">Numéro de téléphone</label>
                  <input
                    id="telephone"
                    className="border border-gray-300 w-full p-2"
                    type="tel"
                    value={formData.telephone}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div>
                <label htmlFor="message" className="block mb-2">Message</label>
                <textarea
                  id="message"
                  className="border border-gray-300 w-full p-2"
                  rows="4"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <button
                  type="submit"
                  className="bg-[#79111C] text-white py-3 px-4 rounded-full hover:bg-[#79111C]"
                >
                  Envoyer le message
                </button>

            </form>
        
          </div>

          {/* Séparateur vertical */}
          <div className="hidden md:flex justify-center items-center mx-6 mt-16">
            <div className="w-px h-96 bg-gray-100"></div>
          </div>

          {/* Informations de contact */}
          <div className="">
            {/* Informations de contact */}
            <h3 className="text-[#AB1827] font-semibold text-lg">France</h3>
            <p><strong>+33 6 24 65 54 28</strong><br />917, avenue Robert Brun,<br /> 83500, La Seyne sur mer</p>

            <h3 className="text-[#AB1827] font-semibold text-lg mt-6">Bénin</h3>
            <p><strong>+229 64 88 28 29</strong><br />3ème étage Janvier AIVODJI<br /> Rue TECNO, face Eglise Sacré-Coeur,<br /> Missesin-Akpakpa, Cotonou</p>
          </div>

        </div>

        {/* Section Google Maps */}
        <div className="mt-100">
          <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.835434509567!2d144.96305761531688!3d-37.81410797975159!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf577597a624801e7!2sFederation%20Square%2C%20Melbourne%20VIC%203000%2C%20Australia!5e0!3m2!1sen!2sus!4v1633715373190!5m2!1sen!2sus"
            className="w-full h-60 sm:h-96"
            frameBorder="0"
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ContactMain;
