import React from 'react';
import { Link } from 'react-router-dom';
import footerLogo1 from '../../../assets/img/logo/logo.png';

const Footer = (props) => {
    const { footerLogo, footerClass, footerTopClass } = props;
    return (
        <footer style={
            {
                marginTop: "50px",
            }
        } className={footerClass ? footerClass : 'rs-footer'}>
            <div className={`footer-top ${footerTopClass}`}>
                <div className="container" style={{ maxWidth: '80%', margin: '0 auto' }}>
                    <div className="row">
                        {/* Logo and Description */}
                        <div className="col-lg-4 col-md-12 col-sm-12 footer-widget md-mb-50">
                            <div className="footer-logo mb-30">
                                <Link to="/">
                                    <img src={footerLogo ? footerLogo : footerLogo1} alt="Logo" />
                                </Link>
                            </div>
                            <div className="pr-60 md-pr-14">
                                <p style={{fontStyle: "normal", fontWeight: "400", fontSize: "16px", color: "#B7AFAF", lineHeight: "23.2px"}}>
                                    Nous offrons des formations en ligne de qualité, conçues pour vous aider à développer vos compétences professionnelles et personnelles. Rejoignez notre communauté et accédez à des ressources pédagogiques modernes, flexibles et adaptées à vos besoins ou à ceux de vos collaborateurs.
                                </p>
                                <span className="!pc" style={{ fontSize: "14px", color: "#B7AFAF" }}>Email : contact@akambi.com</span>
                                <br />
                                <span className="!pc" style={{ fontSize: "14px" }}>© 2024 Akambi Consulting. Tous droits réservés.</span>
                            </div>
                        </div>

                        {/* Nos Services */}
                        <div className="col-lg-3 col-md-12 col-sm-12 footer-widget md-mb-50">
                            <h3 style={{ fontFamily: "Inter", fontWeight: "500", fontSize: "16px", color: "#CDC4C4" }}>Nos services</h3>
                            <ul className="address-widget">
                                <li><Link to="/formations-en-ligne" style={{ color: "#645D5D" }}>Formations en ligne</Link></li>
                                <li><Link to="/formations-en-presentiel" style={{ color: "#645D5D" }}>Formations en présentiel</Link></li>
                                <li><Link to="/certifications" style={{ color: "#645D5D" }}>Certifications professionnelles</Link></li>
                                <li><Link to="/support" style={{ color: "#645D5D" }}>Support personnalisé</Link></li>
                                <li><Link to="/partenariat" style={{ color: "#645D5D" }}>Partenariat et collaboration</Link></li>
                            </ul>
                        </div>

                        {/* Solutions pour Entreprises */}
                        <div className="col-lg-3 col-md-12 col-sm-12 footer-widget md-mb-50">
                            <h3 style={{ fontFamily: "Inter", fontWeight: "500", fontSize: "16px", color: "#CDC4C4" }}>Solutions pour entreprises</h3>
                            <ul className="address-widget">
                                <li><Link to="/formations-par-secteur" style={{ color: "#645D5D" }}>Formations spécifiques par secteur</Link></li>
                                <li><Link to="/developpement-competences" style={{ color: "#645D5D" }}>Programmes de développement de compétences</Link></li>
                                <li><Link to="/transformation-digitale" style={{ color: "#645D5D" }}>Accompagnement pour la transformation digitale</Link></li>
                                <li><Link to="/suivi-performances" style={{ color: "#645D5D" }}>Suivi et évaluation des performances de vos équipes</Link></li>
                                <li><Link to="/packs-formations" style={{ color: "#645D5D" }}>Packs de formations personnalisés</Link></li>
                            </ul>
                        </div>

                        {/* Liens Utiles */}
                        <div className="col-lg-2 col-md-12 col-sm-12 footer-widget md-mb-50">
                            <h3 style={{ fontFamily: "Inter", fontWeight: "500", fontSize: "16px", color: "#CDC4C4" }}>Liens utiles</h3>
                            <ul className="address-widget">
                                <li><Link to="/faq" style={{ color: "#645D5D" }}>FAQ</Link></li>
                                <li><Link to="/politique-confidentialite" style={{ color: "#645D5D" }}>Politique de confidentialité</Link></li>
                                <li><Link to="/conditions-utilisation" style={{ color: "#645D5D" }}>Conditions d'utilisation</Link></li>
                                <li><Link to="/contact-support" style={{ color: "#645D5D" }}>Support/Contact</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
