import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { CartContext } from './CartContext';
import { initiateFedaPayPayment, confirmStripePayment } from '../../services/payment';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

const stripePromise = loadStripe('VOTRE_CLE_PUBLIQUE_STRIPE');

// Styles de base
const MainContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Roboto', Arial, sans-serif;
`;

const Header = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #2c3e50;
`;

const SubHeader = styled.h2`
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: #2c3e50;
`;

const Layout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap; /* Ajouté pour la réactivité */
`;

const PaymentOptions = styled.div`
  flex: 3;
  padding-right: 20px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    flex: 1;
    padding-right: 0;
  }
`;

const Option = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ecf0f1;
  border-radius: 8px;
  padding: 15px 20px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: border-color 0.3s ease;

  &:hover {
    border-color: #34495e;
  }

  &.selected {
    border-color: #a60b0b;
    background-color: #f8d7da;
  }

  input {
    display: none;
  }

  input:checked + .radio-custom {
    border: 5px solid #a60b0b;
  }

  .radio-custom {
    width: 20px;
    height: 20px;
    border: 5px solid #ecf0f1;
    border-radius: 50%;
    margin-right: 15px;
  }
`;

const OptionText = styled.div`
  font-size: 1rem;
  color: #2c3e50;
  flex-grow: 1;
`;

const OptionIcon = styled.img`
  width: 50px;
  height: 30px;
`;

const Summary = styled.div`
  flex: 1.5;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  margin-top: -100px; /* Ajustement pour faire monter la section */
  width: 100%; /* Assurez-vous que l'élément prend toute la largeur disponible sur mobile */
  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

const SummaryHeader = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #2c3e50;
  text-align: left;
  width: 100%;
`;

const SummaryTotal = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 1.2rem;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 20px;
`;

const CheckoutButton = styled.button`
  background-color: #a60b0b;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1.2rem;
  margin-bottom: 20px;
  width: 100%;

  &:hover {
    background-color: #8b0000;
  }
`;

const OrderDetails = styled.div`
  width: 100%;
`;

const OrderItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
  font-size: 0.9rem;
`;

const ItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 10px;
`;

const ItemImage = styled.img`
  width: 60px;
  height: 40px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 4px;
`;

const ItemTitle = styled.div`
  font-weight: bold;
  color: #2c3e50;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ItemPrice = styled.div`
  color: #2c3e50;
  white-space: nowrap;
`;

const StripeFormContainer = styled.div`
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;

  h3 {
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: 600;
  }

  .StripeElement {
    margin-bottom: 16px;
    padding: 10px 12px;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    font-size: 16px;
    color: #2d3748;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }

  .StripeElement--focus {
    border-color: #3182ce;
    outline: none;
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }

  .StripeElement--invalid {
    border-color: #e53e3e;
  }

  .StripeElement::placeholder {
    color: #a0aec0;
  }

  label {
    font-size: 14px;
    color: #4a5568;
    margin-bottom: 6px;
    display: block;
  }
`;

// Composant principal
function ConfirmationPage() {
  const { cartItems } = useContext(CartContext);
  const [selectedPayment, setSelectedPayment] = useState(3); // Par défaut, MTN Mobile Money
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const stripe = useStripe();
  const elements = useElements();

  const paymentMethods = [
    { id: 1, method: 'Payer par carte', buttonText: 'Valider la commande' },
    { id: 2, method: 'Payer par PayPal', buttonText: 'Continuer avec PayPal' },
    { id: 3, method: 'Payer par Mobile Money', buttonText: 'Continuer avec MTN Mobile Money' },
    { id: 4, method: 'Payer par Moov Money', buttonText: 'Continuer avec Moov Money' },
  ];

  const total = cartItems.reduce((sum, item) => sum + item.price, 0);
  const courseIds = cartItems.map(item => item.id);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const status = params.get('status');
    const trabnsactionId = params.get('id');
    const close = params.get('close');
    const id = params.get('id');

    if (status) {
      console.log(`Status: ${status}`);
      // Vous pouvez afficher une notification ou effectuer une action en fonction du statut
      if (status === 'pending') {
        toast.info('Votre paiement est en attente.');
      } else if (status === 'success') {
        toast.success('Votre paiement a été réussi.');
      } else if (status === 'failed') {
        toast.error('Votre paiement a échoué.');
      }
    }
  }, [location]);

  const handleCheckout = async () => {
    setLoading(true);
    try {
      if (selectedPayment === 3 || selectedPayment === 4) {
        const data = await initiateFedaPayPayment(total, courseIds, selectedPayment);
        window.location.href = data.data.payment_url;
      } else if (selectedPayment === 1) {
        if (!stripe || !elements) {
          alert('Stripe n’est pas correctement initialisé.');
          setLoading(false);
          return;
        }
        try {
          const successMessage = await confirmStripePayment(stripe, elements, total);
          alert(successMessage);
        } catch (error) {
          alert(`Le paiement a échoué : ${error.message}`);
        }
      }
    } catch (error) {
      toast.error('Erreur lors de la création de la transaction');
    } finally {
      setLoading(false);
    }
  };

  return (
    <MainContainer>
      <Header>Confirmer votre achat</Header>
      <SubHeader>Choisissez votre moyen de paiement</SubHeader>
      <Layout>
        <PaymentOptions>
          {paymentMethods.map((method) => (
            <Option
              key={method.id}
              className={selectedPayment === method.id ? 'selected' : ''}
              onClick={() => setSelectedPayment(method.id)}
            >
              <input type="radio" name="payment" checked={selectedPayment === method.id} readOnly />
              <span className="radio-custom" />
              <OptionText>{method.method}</OptionText>
            </Option>
          ))}
          {selectedPayment === 1 && (
            <Elements stripe={stripePromise}>
              <StripeFormContainer>
                <h3>Vos informations de paiement</h3>
                <CardElement
                  options={{
                    style: {
                      base: {
                        fontSize: '16px',
                        color: '#424770',
                        '::placeholder': { color: '#aab7c4' },
                      },
                      invalid: { color: '#9e2146' },
                    },
                  }}
                />
              </StripeFormContainer>
            </Elements>
          )}
        </PaymentOptions>
        <Summary>
          <SummaryHeader>Récapitulatif</SummaryHeader>
          <SummaryTotal>
            <div>Total de la commande</div>
            <div>{total.toLocaleString()} FCFA</div>
          </SummaryTotal>
          <CheckoutButton onClick={handleCheckout} disabled={loading}>
            {loading ? 'Chargement...' : paymentMethods.find((method) => method.id === selectedPayment)?.buttonText}
          </CheckoutButton>
          <OrderDetails>
            {cartItems.map((item) => (
              <OrderItem key={item.id}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ItemImage src={item.imageUrl} alt={item.title} />
                  <ItemDetails>
                    <ItemTitle>{item.title}</ItemTitle>
                  </ItemDetails>
                </div>
                <ItemPrice>{item.price.toLocaleString()} FCFA</ItemPrice>
              </OrderItem>
            ))}
          </OrderDetails>
        </Summary>
      </Layout>
    </MainContainer>
  );
}

export default ConfirmationPage;
