import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import SectionTitle from '../../components/Common/SectionTitle';
import { FaArrowRight } from 'react-icons/fa';
import icon1 from '../../assets/img/categories/icons/1.png';

// Styled-components
const CategoriesContainer = styled.div`
  position: relative;
  background-color: rgba(247, 232, 233, 0.4);
  padding: 94px 20px 70px;

  @media (max-width: 768px) {
    padding: 64px 10px 40px;
  }
`;

const CategoriesContent = styled.div`
  width: 80%;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const CategoriesButton = styled(Link)`
  background-color: #ffffff;
  border: 1.5px solid #9c1623;
  border-radius: 37px;
  padding: 8px 16px;
  font-family: Inter, sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #ab1927;
  text-align: center;
  white-space: nowrap;
  display: inline-block;

  &:hover {
    background-color: #9c1623;
    color: #ffffff;
  }

  @media (max-width: 768px) {
    width: 100%;
    font-size: 14px;
    text-align: center;
    margin-top: 20px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin: 0 auto;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled.div`
  background: #ffffff;
  border: none;
  text-align: left;
  padding: 30px;
  display: flex;
  align-items: center;
  gap: 15px;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translateY(-5px);
  }

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const IconWrapper = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #ffe8e9;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  overflow: hidden;

  img {
    width: 30px;
    height: 30px;
  }

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;

  h4 {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    margin: 0;
  }

  p {
    font-size: 14px;
    color: #000;
    display: flex; /* Flexbox pour aligner horizontalement */
    align-items: center; /* Alignement vertical */
    margin: 0;

    a {
      display: flex;
      align-items: center; /* Alignement vertical de la flèche */
      text-decoration: none;
      color: inherit;

      &:hover {
        color: #9c1623; /* Optionnel : changement de couleur au survol */
      }
    }
  }

  @media (max-width: 768px) {
    align-items: center;
  }
`;


const Categories = ({ categories }) => {
  return (
    <CategoriesContainer>
      <CategoriesContent>
        <div className="row align-items-center mb-50">
          <div className="col-md-6">
            <SectionTitle
              sectionClass="sec-title"
              subtitleClass="sub-title primary"
              subtitle="CATEGORIES DE COURS"
              titleClass="title mt-0"
              title="Les premiers choix !"
              descClass="desc big"
              description="Découvrez comment nous façonnons le futur de l'éducation. Découvrez nos cours dès maintenant !"
            />
          </div>
          <div className="col-md-6 text-right">
            <CategoriesButton to="/all-categories">
              Voir toutes les catégories
            </CategoriesButton>
          </div>
        </div>

        {/* Grille des catégories */}
        <Grid>
          {categories.length > 0 ? (
            categories.map((categorie, i) => (
              <Card key={i}>
                <IconWrapper>
                  <img src={icon1} alt="icon" />
                </IconWrapper>
                <CardContent>
                  <h4>{categorie.name}</h4>
                  <p>
                  <Link to={`/course-categories/${categorie.id}`}>
                    {categorie.courses_count} cours <FaArrowRight style={{ marginLeft: '5px' }} />
                  </Link>
                </p>

                </CardContent>
              </Card>
            ))
          ) : (
            <p>Aucune catégorie disponible pour le moment.</p>
          )}
        </Grid>
      </CategoriesContent>
    </CategoriesContainer>
  );
};

export default Categories;
