import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import SectionTitle from "../../components/Common/SectionTitle";
import CourseSingle from "../../components/Courses/CourseSingle";
import { getLastCourses } from "../../services/course";
import image1 from "../../assets/img/courses/main-home/1.jpg";
import ReactPaginate from "react-paginate";

const CoursesContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 100px;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  .pagination {
    display: flex;
    list-style: none;
    padding: 0;

    li {
      margin: 0 5px;

      &.selected a {
        background-color: #AB1827;
        color: white;
        border-radius: 50%;
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border: 1px solid #ddd;
        border-radius: 50%;
        cursor: pointer;
        color: #333;
        background-color: #fff;

        &:hover {
          background-color: #f8f9fa;
        }
      }
    }

    @media (max-width: 768px) {
      li a {
        width: 25px;
        height: 25px;
        font-size: 12px;
      }
    }
  }
`;


const CoursesContent = styled.div`
  max-width: 80%;
  margin: 0 auto;
`;

const CoursesRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CoursesButton = styled(Link)`
  background-color: #ffffff;
  border: 1.5px solid #9c1623;
  border-radius: 37px;
  width: 205px;
  height: 36px;
  padding: 8px 16px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 145%;
  color: #ab1927;
  text-align: center;
`;

const Courses = () => {
  const [courses, setCourses] = useState([]);

  const fetchCourses = async () => {
    try {
      const response = await getLastCourses();
      //console.log('API response:', response);

      if (response && Array.isArray(response.data)) {
        setCourses(response.data);
      } else {
        //console.error('Courses data is not an array:', response.data);
      }
    } catch (error) {
      //console.error('Failed to fetch courses:', error);
    }
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  return (
    <CoursesContainer className="rs-popular-courses main-home event-bg pt-100 pb-100 md-pt-70 md-pb-70">
      <CoursesContent className="container">
        <CoursesRow className="row y-middle mb-50 md-mb-30">
          <div className="col-md-6 sm-mb-30">
            <SectionTitle
              sectionClass="sec-title"
              subtitleClass="sub-title primary"
              subtitle={<div>COURS</div>}
              titleClass="title mt-0"
              title="Cours populaires"
              descClass="desc big"
              description={
                <div
                  style={{
                    fontFamily: "'MetropolisMedium', sans-serif",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "145%",
                    marginTop: "10px",
                  }}
                >
                  Découvrez les cours les plus suivis ces trois derniers mois !
                </div>
              }
            />
          </div>
          <div className="col-md-6">
            <div className="btn-part text-right sm-text-left">
              <CoursesButton to="/course">Voir tous les cours</CoursesButton>
            </div>
          </div>
        </CoursesRow>
        <div className="row">
          {Array.isArray(courses) && courses.length > 0 ? (
            courses.map((course) => (
              <div key={course.id} className="col-lg-3 col-md-6 mb-30">
                <CourseSingle
                  itemClass="courses-item"
                  image={course.cover ? course.cover : image1}
                  title={course.title}
                  pricing={course.price}
                  devise={course.devise}
                  studentQuantity={course.student_quantity}
                  lessonsQuantity={course.lessons_quantity}
                  courseLink={course.course_link}
                  author={course.author}
                  ratings={course.ratings}
                  id={course.id} // Assure-toi de passer l'ID ici
                />
              </div>
            ))
          ) : (
            <div>No courses available</div>
          )}
        </div>
        {/* <PaginationContainer>
          <ReactPaginate
            initialPage={0}
            breakLabel="..."
            nextLabel=">"
            onPageChange={(v) => {
              setPage(v.selected + 1);
            }}
            pageRangeDisplayed={5}
            previousLabel="<"
            pageCount={Math.ceil(totalElement / 10)}
            marginPagesDisplayed={2}
            containerClassName={"pagination"}
          />
        </PaginationContainer> */}
      </CoursesContent>
    </CoursesContainer>
  );
};

export default Courses;
