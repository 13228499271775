export const API_BASE_URL ='https://api.tictechsmart.com/api/v1';

export const Local_API_BASE_URL ='http://localhost:8000/api/v1';

export const NGROCK_URL = 'https://0149-81-91-231-58.ngrok-free.app/api/v1';

export const token = localStorage.getItem('token');
export const headers = {
    Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
}
  
export const ressetlink= window.location.hostname