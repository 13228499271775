import axios from 'axios';
import { API_BASE_URL } from '.';

const fetchTeachers = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/teachers`);
    return response.data; // Supposons que l'API renvoie un tableau d'instructeurs
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

const getCountTeachers = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/teachers/count`);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

export default {
  fetchTeachers,
  getCountTeachers,
};
