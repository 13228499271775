import React from 'react';
import styled from 'styled-components';

import img2 from 'assets/img/about/Rectangle 737.png';
import img3 from 'assets/img/about/Rectangle 738.png';

const HeroSection = styled.section`
  width: 90%;
  margin: 4rem auto;
  background-color: #ffffff;

  @media (min-width: 1024px) {
    width: 80%;
  }
`;

const HeroTextContainer = styled.div`
  margin-bottom: 2rem;
  margin: 4rem auto;
  width: 100%;

  @media (min-width: 768px) {
    width: 90%;
  }
`;

const TitleMarker = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const MarkerLine = styled.div`
  width: 25px;
  height: 2.4px;
  background-color: #ab1827;
  margin-right: 20px;
`;

const SectionLabel = styled.span`
  color: #ab1827;
  font-size: 14px;
  line-height: 16.8px;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
`;

const HeroTextRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const HeroTitle = styled.h1`
  font-size: 16px;
  line-height: 40px;
  font-weight: 700;
  letter-spacing: -2px;
  margin: 0 0 1.5rem 0;
  color: #000;

  @media (min-width: 768px) {
    font-size: 48px;
    line-height: 52px;
    letter-spacing: -3px;
    margin: 0;
  }

  @media (min-width: 1024px) {
    font-size: 56px;
    line-height: 56px;
    letter-spacing: -4px;
  }
`;

const HeroSubtitle = styled.p`
  font-size: 14px;
  color: #101012;
  font-weight: 400;
  line-height: 22px;
  margin: 0;
  max-width: 100%;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    max-width: 40%;
  }
`;

const HeroImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const HeroImageLarge = styled.img`
  flex: 2;
  width: 100%;
  height: auto;
  object-fit: cover;
`;

const HeroImageSmall = styled.img`
  flex: 1;
  width: 100%;
  height: auto;
  object-fit: cover;
`;

const Hero = () => (
  <HeroSection>
    <HeroTextContainer>
      <TitleMarker>
        <MarkerLine />
        <SectionLabel>AKAMBI Consulting</SectionLabel>
      </TitleMarker>
      <HeroTextRow>
        <HeroTitle>
        VOTRE RÉFÉRENCE<br />INTERNATIONALE<br />EN FORMATION <br />
        </HeroTitle>
        <HeroSubtitle>
        Espace sans frontière où l’innovation, l’engagement et l’excellence convergent 
        pour former le socle de notre centre de formation. Chez nous, l’apprentissage 
        n’est pas simplement un processus, c’est une aventure qui transcende les barrières 
        géographiques et temporelles.
        </HeroSubtitle>
      </HeroTextRow>
    </HeroTextContainer>
    <HeroImagesContainer>
      <HeroImageLarge src={img2} />
      <HeroImageSmall src={img3} />
    </HeroImagesContainer>
  </HeroSection>
);

export default Hero;
