import React, { useState, useEffect } from 'react';
import SliderDefault from '../../components/Slider/SliderDefault';
import About from './About';
import Categories from './CategoriesSection';
import Courses from './CoursesSection';
import Cta from './CtaSection';
import Testimonial from './TestimonialSection';
import ScrollToTop from '../../components/Common/ScrollTop';
import { fetchCategoryForHomePage } from 'services/categorieCourses';

const HomeMain = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  const loadCategories = async () => {
    try {
      const response = await fetchCategoryForHomePage();
      console.log({ response });
      setCategories(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    loadCategories().then(r => console.log('Categories loaded'));
  }, []);

  console.log(categories);

  return (
    <React.Fragment>
      <SliderDefault />
      <About />
      <Categories categories={categories} loading={loading} />
      <Courses />
      <Cta />
      <Testimonial />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default HomeMain;