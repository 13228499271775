import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import CourseCard from './CourseCard';
import { FaChevronLeft, FaChevronRight, FaChevronUp, FaChevronDown } from 'react-icons/fa'; // Add the missing imports
import { getAllCourseForAllUser, getCourseAll, getCourseForUser } from 'services/course';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;

  @media (max-width: 768px) {
    padding: 0 15px;
  }
`;

const HeroImageContainer = styled.div`
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
`;

const HeroImage = styled.div`
  width: 100%;
  height: 400px;
  background-image: url('https://images.unsplash.com/photo-1507537297725-24a1c029d3ca');
  background-size: cover;
  background-position: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    height: 300px;
  }

  @media (max-width: 480px) {
    height: 200px;
  }
`;

const TitleSection = styled.div`
  margin-bottom: 10px;
  
  display: flex;
  justify-content: flex-start; /* Aligne à gauche */
  width: 100%; /* Prend toute la largeur */
`;

const Title = styled.h1`
  font-size: 2em;
  color: #333;
  margin: 0;

  @media (max-width: 480px) {
    font-size: 1.5em;
  }
`;


const SearchSection = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  flex-wrap: wrap;
`;

const SearchBar = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  width: 100%; /* Prend toute la largeur de la zone des cours */

  input {
    flex: 1;
    padding: 15px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 50px 0 0 50px;
    border-right: none;
    outline: none;
  }

  button {
    padding: 15px 30px;
    font-size: 1em;
    border: none;
    border-radius: 0 50px 50px 0;
    background-color: #AB1827;
    color: white;
    cursor: pointer;
    outline: none;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    input {
      border-radius: 50px;
      margin-bottom: 10px;
    }

    button {
      border-radius: 50px;
      width: 100%;
    }
  }
`;


const FilterSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  align-items: flex-start;
  min-height: 100vh;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Filters = styled.div`
  min-width: 250px;
  padding-right: 20px;
  height: 100%;
  /* Supprimez le trait vertical */
  border-right: none;

  @media (max-width: 768px) {
    padding-right: 0;
    margin-bottom: 20px;
  }
`;


const FilterGroup = styled.div`
  margin-bottom: 20px;

  h4 {
    margin-bottom: 10px;
    font-size: 1.2em;
    color: #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .toggle-icon {
      font-size: 1em;
    }
  }

  label {
    display: block;
    margin-bottom: 10px;
    font-size: 0.9em;
    color: #555;
    display: flex;
    align-items: center;

    input {
      margin-right: 10px;
    }

    .rating-stars {
      color: #f39c12;
      margin-right: 5px;
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;
  }
`;

const CoursesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  padding-left: 20px;
  flex: 1;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    padding-left: 0;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const NavigationButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60px; /* Ajustez la largeur si nécessaire */
  height: 40px;
  background-color: #333; /* Couleur du fond */
  color: #fff; /* Couleur des icônes */
  border: none;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: #555; /* Couleur au survol */
  }
`;

const Arrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  color: ${props => (props.disabled ? '#aaa' : '#fff')};

  &:hover {
    background-color: ${props => (props.disabled ? 'none' : '#444')};
  }
`;


const PageNumberButton = styled.button`
  padding: 8px 16px;
  font-size: 1rem;
  color: ${props => (props.selected ? '#fff' : '#333')};
  background-color: ${props => (props.selected ? '#AB1827' : '#fff')};
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #f8f9fa;
  }

  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
`;

const PageInfo = styled.span`
  font-size: 1rem;
  color: #333;

  @media (max-width: 480px) {
    display: none; /* Masque le texte sur mobile */
  }
`;



function CourseCatalog() {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalElement, setTotalElement] = useState(0);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [expanded, setExpanded] = useState({
    notes: true,
    skillLevel: true,
    priceLevel: true,
  });

  const toggleExpand = (section) => {
    setExpanded(prevState => ({
      ...prevState,
      [section]: !prevState[section]
    }));
  };

  useEffect(() => {
    const data = {
      page,
      per_page: 15,
      search,
    };
    getCourseForUser(data)
      .then(response => {
        setCourses(response.data.data.data);
        setTotalElement(response.data.data.total);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  }, [page, search]);

  const totalPages = Math.ceil(totalElement / 15);

  return (
    <PageContainer>
      <HeroImageContainer>
        <HeroImage />
      </HeroImageContainer>
      <SearchSection>
        <TitleSection>
          <Title>Tous nos cours</Title>
        </TitleSection>
      </SearchSection>

      <FilterSection>
        <Filters>
          <FilterGroup>
            <h4 onClick={() => toggleExpand('notes')}>
              Notes
              <span className="toggle-icon">
                {expanded.notes ? <FaChevronUp /> : <FaChevronDown />}
              </span>
            </h4>
            {expanded.notes && (
              <>
                <label>
                  <input type="radio" name="rating" />
                  <span className="rating-stars">★★★★★</span>
                  <span>A partir de 4,5 (1500)</span>
                </label>
                <label>
                  <input type="radio" name="rating" />
                  <span className="rating-stars">★★★★☆</span>
                  <span>A partir de 4,0 (1000)</span>
                </label>
                <label>
                  <input type="radio" name="rating" />
                  <span className="rating-stars">★★★☆☆</span>
                  <span>A partir de 3,5 (500)</span>
                </label>
                <label>
                  <input type="radio" name="rating" />
                  <span className="rating-stars">★★☆☆☆</span>
                  <span>A partir de 3,0 (200)</span>
                </label>
              </>
            )}
          </FilterGroup>
          <FilterGroup>
            <h4 onClick={() => toggleExpand('skillLevel')}>
              Skill Level
              <span className="toggle-icon">
                {expanded.skillLevel ? <FaChevronUp /> : <FaChevronDown />}
              </span>
            </h4>
            {expanded.skillLevel && (
              <>
                <label><input type="radio" name="level" /> Beginner</label>
                <label><input type="radio" name="level" /> Intermediate</label>
                <label><input type="radio" name="level" /> Expert</label>
              </>
            )}
          </FilterGroup>
          <FilterGroup>
            <h4 onClick={() => toggleExpand('priceLevel')}>
              Price Level
              <span className="toggle-icon">
                {expanded.priceLevel ? <FaChevronUp /> : <FaChevronDown />}
              </span>
            </h4>
            {expanded.priceLevel && (
              <>
                <label><input type="radio" name="price" /> Free (14)</label>
                <label><input type="radio" name="price" /> Paid (26)</label>
              </>
            )}
          </FilterGroup>
        </Filters>
      <div style={{ flex: 1 }}> {/* Conteneur pour les cours et la barre de recherche */}
        <SearchBar>
          <input
            type="text"
            placeholder="Ex. Web developer"
            onChange={(e) => setSearch(e.target.value)}
          />
          <button>Rechercher</button>
        </SearchBar>
        <CoursesGrid>
          {courses.map((course, index) => (
            <CourseCard key={index} course={course} />
          ))}
        </CoursesGrid>

         {/* Pagination */}
      <PaginationContainer>
        <div className="flex items-center justify-center mt-12 space-x-4 mt-20">
          {/* Texte Page */}
        
          <PageInfo>Page {page} sur {totalPages}</PageInfo>

          {/* Numéros des pages */}
          <nav className="inline-flex items-center space-x-2">
            {Array.from({ length: totalPages }, (_, index) => (
              <PageNumberButton
                key={index}
                selected={page === index + 1}
                onClick={() => setPage(index + 1)}
              >
                {index + 1}
              </PageNumberButton>
            ))}

            {/* Boutons de navigation */}
            <NavigationButton>
        {/* Flèche gauche */}
        <Arrow
          onClick={() => page > 1 && setPage(page - 1)}
          disabled={page === 1}
        >
          <FaChevronLeft />
        </Arrow>

        {/* Flèche droite */}
        <Arrow
          onClick={() => page < totalPages && setPage(page + 1)}
          disabled={page === totalPages}
        >
          <FaChevronRight />
        </Arrow>
      </NavigationButton>
          </nav>
        </div>
      </PaginationContainer>
      </div>
      </FilterSection>
     
    </PageContainer>
  );
}

export default CourseCatalog;
