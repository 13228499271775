import React, { useEffect } from 'react';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import styled from 'styled-components';
import GlobalStyle from './GlobalStyle';
import { FaHome, FaBook, FaCertificate, FaComments, FaMoneyBill, FaUser, FaContao, FaCalendar, FaHackerNewsSquare } from 'react-icons/fa';
import AuthProvider, { useAuth } from 'contexts/AuthProvider';
import { Outlet } from 'react-router-dom';

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
`;


function AppDashboard() {
  const { user } = useAuth();
  let menuItems = [];

  if (user?.role === 'admin') {
    menuItems = [
      { to: '/dashboard', icon: <FaHome />, label: 'Accueil' },
      { to: '/dashboard/users', icon: <FaUser />, label: 'Utilisateurs' },
      { to: '/dashboard/courses', icon: <FaBook />, label: 'Cours' },
      { to: '/dashboard/certifications', icon: <FaCertificate />, label: 'Certifications' },
      { to: '/dashboard/discussions', icon: <FaComments />, label: 'Discussion' },
      { to: '/dashboard/revenues', icon: <FaMoneyBill />, label: 'Revenus' },
      { to: '/dashboard/profile', icon: <FaUser />, label: 'Profil' },
      { to: '/dashboard/module', icon: <FaUser />, label: 'Créer un module' },
      { to: '/dashboard/categorie', icon: <FaUser />, label: 'Créer une catégorie' },
    ];
  }

  if (user?.role === 'instructor') {
    menuItems = [
      { to: '/dashboard', icon: <FaHome />, label: 'Accueil' },
      { to: '/dashboard/mycourses', icon: <FaBook />, label: 'Mes cours' },
      { to: '/dashboard/create-course', icon: <FaBook />, label: 'Ajouter un cours' },
      { to: '/dashboard/discussions', icon: <FaComments />, label: 'Discussion' },
      { to: '/dashboard/revenues', icon: <FaMoneyBill />, label: 'Revenus' },
      { to: '/dashboard/profile', icon: <FaUser />, label: 'Profil' },
    ];
  }

  if (user?.role === 'user') {
    
   menuItems = [
    { to: '/dashboard', icon: <FaHome />, label: 'Accueil' },
    { to: '/dashboard/mycourses', icon: <FaBook />, label: 'Mes cours' },
    { to: '/dashboard/nouveau', icon: <FaHackerNewsSquare />, label: 'Nouveau cours' },
    { to: '/dashboard/certifications', icon: <FaCertificate />, label: 'Certifications' },
    { to: '/dashboard/discussions', icon: <FaComments />, label: 'Discussion' },
    { to: '/dashboard/calendrier', icon: <FaCalendar />, label: 'Calendrier' },
    { to: '/dashboard/profile', icon: <FaUser />, label: 'Profil' },
    { to: '/dashboard/contacter', icon: <FaContao />, label: 'Nous contacter' },
  ];
}

  return (
    <>
      <GlobalStyle />
      <AppContainer>
        <Header />
        <ContentWrapper>
          <Sidebar menuItems={menuItems} />
          <Outlet />
        </ContentWrapper>
      </AppContainer>
    </>
  );
}

export default AppDashboard;
