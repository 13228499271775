import React, { useEffect } from 'react';
import AOS from 'aos';
import styled from 'styled-components';

const StyledSectionTitle = styled.div`
    margin-bottom: 30px;
`;

const Subtitle = styled.h3`
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 2px;
    color: #AB1827;
    display: flex;
    align-items: center;

    &::before {
        content: '';
        display: block;
        width: 25px;
        height: 2.4px;
        background-color: #AB1827;
        margin-right: 20px;
    }
`;

const SectionTitle = (props) => {
    const { titleClass, subtitleClass, bottomSubTitleClass, descClass, secondDescClass, effectClass, animateName, animateDelay, animateDuration } = props;

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <StyledSectionTitle className={props.sectionClass} data-aos={animateName ? animateName : ''} data-aos-delay={animateDelay ? animateDelay : ''} data-aos-duration={animateDuration ? animateDuration : ''}>
            {subtitleClass &&
                <Subtitle>{props.subtitle}</Subtitle>
            }
            {titleClass &&
                <h2 className={props.titleClass}>{props.title}</h2>
            }
            {bottomSubTitleClass &&
                <div className={props.bottomSubTitleClass}>{props.bottomSubTitle}</div>
            }
            {descClass &&
                <div className={props.descClass}>{props.description}</div>
            }
            {secondDescClass &&
                <div className={props.secondDescClass}>{props.secondDescription}</div>
            }
            {effectClass &&
                <div className={props.effectClass}></div>
            }
        </StyledSectionTitle>
    );
}

export default SectionTitle;
