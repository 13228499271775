import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import SectionTitle from "../../components/Common/SectionTitle";
import CategoriesSingleThree from "../../components/Categories/CategoriesSingleThree";

import icon1 from "../../assets/img/categories/icons/1.png";

// Styled-components
const CategoriesContainer = styled.div`
  position: relative;
  background-color: rgba(247, 232, 233, 0.4);
  padding: 94px 20px 70px;

  @media (max-width: 768px) {
    padding: 64px 10px 40px;
  }
`;

const CategoriesContent = styled.div`
  width: 80%;
  //max-width: 1200px;
  margin: 0 auto;
`;

const CategoriesButton = styled(Link)`
  background-color: #ffffff;
  border: 1.5px solid #9c1623;
  border-radius: 37px;
  padding: 8px 16px;
  font-family: Inter, sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #ab1927;
  text-align: center;
  white-space: nowrap;
  display: inline-block;

  &:hover {
    background-color: #9c1623;
    color: #ffffff;
  }

  @media (max-width: 768px) {
    width: 100%;
    font-size: 14px;
  }
`;

const CategoriesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
`;

const Categories = ({ categories }) => {
  console.log({ categories });
  return (
    <CategoriesContainer>
      <CategoriesContent>
        <div className="row align-items-center mb-50">
          <div className="col-md-6">
            <SectionTitle
              sectionClass="sec-title"
              subtitleClass="sub-title primary"
              subtitle="CATEGORIES DE COURS"
              titleClass="title mt-0"
              title="Les premiers choix !"
              descClass="desc big"
              description="Découvrez comment nous façonnons le futur de l'éducation. Découvrez nos cours dès maintenant !"
            />
          </div>
          <div className="col-md-6 text-right">
            <CategoriesButton to="/all-categories">
              Voir toutes les catégories
            </CategoriesButton>
          </div>
        </div>

        <CategoriesGrid>
          {categories.map((categorie, i) => (
            <CategoriesSingleThree
              key={i}
              categoriesClass="subject-wrap bgc5"
              title={categorie.name}
              courseQuantity={categorie.courses_count}
              iconImg={icon1}
              categoryId={categorie.id}
            />
          ))}
        </CategoriesGrid>
      </CategoriesContent>
    </CategoriesContainer>
  );
};

export default Categories;
