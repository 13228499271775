import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer/Footer';
import OffWrap from '../../components/Layout/Header/OffWrap';
import SearchModal from '../../components/Layout/Header/SearchModal';
import ScrollToTop from '../../components/Common/ScrollTop';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

// Image imports
import favIcon from '../../assets/img/fav-orange.png';
import Logo from '../../assets/img/logo/dark-logo.png';
import footerLogo from '../../assets/img/logo/lite-logo.png';
import bannerbg from '../../assets/img/breadcrumbs/2.jpg';

// Event Images
import eventImg1 from '../../assets/img/event/home12/1.jpg';
import eventImg2 from '../../assets/img/event/home12/2.jpg';
import eventImg3 from '../../assets/img/event/home12/3.jpg';
import eventImg4 from '../../assets/img/event/home12/4.jpg';

const EventCard = ({ image, title, description, date }) => {
    const navigate = useNavigate();

    const handleJoin = () => {
        navigate('/event-details');
    };

    return (
        <div className="bg-white overflow-hidden">
            <img className="w-full h-40 object-cover" src={image} alt={title} />
            <div>
                <h3 className="text-sm text-gray-500 mb-2">New Margania</h3>
                <h2 className="text-lg font-semibold text-gray-900 mb-2">{title}</h2>
                <p className="text-sm text-gray-600 mb-4">{description}</p>
                <div className="flex justify-between items-center">
                    <div className="flex items-center text-sm text-gray-600">
                        <FaRegCalendarAlt className="mr-2 text-red-500" />
                        {date}
                    </div>
                    <button
                        className="px-4 py-1.5 border border-red-700 text-red-700 text-sm rounded-full hover:bg-red-700 hover:text-white transition duration-200"
                        onClick={handleJoin}
                    >
                        Rejoindre
                    </button>
                </div>
            </div>
        </div>
    );
};

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

    return (
        <div className="flex items-center justify-center mt-24 space-x-4">
            {/* Affichage de la page actuelle */}
            <span className="text-gray-600 hidden sm:block">Page {currentPage} sur {totalPages}</span>

            {/* Conteneur pour les numéros de page */}
            <div className="flex items-center space-x-2">
                {pageNumbers.map((page) => (
                    <button
                        key={page}
                        className={`px-3 py-2 ${
                            page === currentPage
                                ? 'border-2 border-[#AB1827] text-gray-900 rounded-md' // Bordure rouge uniquement pour la page active
                                : 'text-gray-600' // Pas de bordure pour les autres pages
                        } bg-transparent`}
                        onClick={() => onPageChange(page)}
                    >
                        {page}
                    </button>
                ))}

                {/* Conteneur pour les flèches avec bordure et fond */}
                <div className="flex items-center border rounded-full bg-[#26272B]">
                    <button
                        className={`px-4 py-2 rounded-full flex items-center ${
                            currentPage === 1 ? 'text-gray-400 cursor-not-allowed' : 'text-white'
                        }`}
                        onClick={() => onPageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        <FaChevronLeft />
                    </button>
                    <button
                        className={`px-4 py-2 rounded-full flex items-center ${
                            currentPage === totalPages ? 'text-gray-400 cursor-not-allowed' : 'text-white'
                        }`}
                        onClick={() => onPageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        <FaChevronRight />
                    </button>
                </div>
            </div>
        </div>
    );
};


const Event = () => {
    const eventsPerPage = 4;
    const [currentPage, setCurrentPage] = useState(1);

    const events = [
        { image: eventImg1, title: "1Persim, Pomegran, And Massag Kale Salad", description: "Bootcamp Events Description Lorem ipsum dolor sit amet, consectetur adipiscing elit", date: "July 24, 2021" },
        { image: eventImg2, title: "Spicy Quince And Cranberry Chutney", description: "Bootcamp Events Description Lorem ipsum dolor sit amet, consectetur adipiscing elit", date: "July 24, 2021" },
        { image: eventImg3, title: "Spicy Quince And Cranberry Chutney", description: "Bootcamp Events Description Lorem ipsum dolor sit amet, consectetur adipiscing elit", date: "July 24, 2021" },
        { image: eventImg4, title: "Spicy Quince And Cranberry Chutney", description: "Bootcamp Events Description Lorem ipsum dolor sit amet, consectetur adipiscing elit", date: "July 24, 2021" },
        { image: eventImg1, title: "Persim, Pomegran, And Massag Kale Salad", description: "Bootcamp Events Description Lorem ipsum dolor sit amet, consectetur adipiscing elit", date: "July 24, 2021" },
        { image: eventImg2, title: "Spicy Quince And Cranberry Chutney", description: "Bootcamp Events Description Lorem ipsum dolor sit amet, consectetur adipiscing elit", date: "July 24, 2021" },
        { image: eventImg3, title: "Spicy Quince And Cranberry Chutney", description: "Bootcamp Events Description Lorem ipsum dolor sit amet, consectetur adipiscing elit", date: "July 24, 2021" },
        { image: eventImg4, title: "Spicy Quince And Cranberry Chutney", description: "Bootcamp Events Description Lorem ipsum dolor sit amet, consectetur adipiscing elit", date: "July 24, 2021" },
    ];

    const totalPages = Math.ceil(events.length / eventsPerPage);

    const currentEvents = events.slice(
        (currentPage - 1) * eventsPerPage,
        currentPage * eventsPerPage
    );

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <React.Fragment>
            <Helmet>
                <link rel="icon" href={favIcon} />
            </Helmet>

            <OffWrap />
            <Header
                parentMenu='evenement'
                headerNormalLogo={Logo}
                headerStickyLogo={Logo}
                CanvasLogo={Logo}
                mobileNormalLogo={Logo}
                CanvasClass="right_menu_togle hidden-md"
                headerClass="full-width-header header-style1 home8-style4"
            />

            <div className="relative">
                <img src={bannerbg} alt="Banner" className="w-full h-72 object-cover" />
            </div>

            <section className="py-16">
                <div className="w-3/4 mx-auto mt-8 px-4">
                    <h1 className="text-3xl font-semibold text-gray-800 mb-25">Événements à venir</h1>

                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-8">
                        {currentEvents.map((event, index) => (
                            <EventCard
                                key={index}
                                image={event.image}
                                title={event.title}
                                description={event.description}
                                date={event.date}
                            />
                        ))}
                    </div>

                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />
                </div>
            </section>

            <Footer
                footerClass="rs-footer home9-style main-home"
                footerLogo={footerLogo}
            />

            <ScrollToTop scrollClassName="scrollup orange-color" />
            <SearchModal />
        </React.Fragment>
    );
};

export default Event;
