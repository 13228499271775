import React from 'react';
import styled from 'styled-components';
import { FaFilePdf, FaSearch, FaSlidersH } from 'react-icons/fa';

const CertificationsContainer = styled.div`
  padding: 20px;
  padding-bottom: 50px; /* Ajout pour éviter que le contenu touche le bord inférieur */
  min-height: 100vh;
  width: 100%;
  overflow-y: auto;
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const FilterSearchContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
`;

const FilterButton = styled.button`
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  position: relative;

  @media (max-width: 768px) {
    flex: 100%;
  }
`;

const SearchInput = styled.input`
  flex: 1;
  padding: 10px;
  padding-right: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const SearchIcon = styled(FaSearch)`
  position: absolute;
  right: 10px;
  color: #ccc;
`;

const CertificationList = styled.div`
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 50px; /* Ajout de marge en bas pour éviter que le contenu soit trop bas */
`;

const CertificationItem = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #eee;

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const PdfIcon = styled(FaFilePdf)`
  font-size: 24px;
  color: #d32f2f;
  margin-right: 20px;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

const CourseInfo = styled.div`
  flex: 1;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const CourseName = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const CertificationDetails = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
  }
`;

const CompletionDate = styled.div`
  font-size: 14px;
  color: #666;
  margin-right: 20px;

  @media (max-width: 768px) {
    margin-right: 0;
  }
`;

const DownloadButton = styled.button`
  padding: 10px 15px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const DownloadIcon = styled(FaFilePdf)`
  margin-left: 5px;
`;

const certificationsData = [
  { name: 'Fitness Development Strategy Buildup Laoreet', date: '21 avril 2024' },
  { name: 'Fitness Development Strategy Buildup Laoreet', date: '21 avril 2024' },
  { name: 'Fitness Development Strategy Buildup Laoreet', date: '21 avril 2024' },
  { name: 'Fitness Development Strategy Buildup Laoreet', date: '21 avril 2024' },
];

const Certifications = () => {
  return (
    <CertificationsContainer>
      <Title>Mes certifications</Title>
      <FilterSearchContainer>
        <FilterButton>
          <FaSlidersH />
          &nbsp; Filtre
        </FilterButton>
        <SearchContainer>
          <SearchInput type="text" placeholder="Rechercher un cours" />
          <SearchIcon />
        </SearchContainer>
      </FilterSearchContainer>
      <CertificationList>
        {certificationsData.map((certification, index) => (
          <CertificationItem key={index}>
            <PdfIcon />
            <CourseInfo>
              <CourseName>{certification.name}</CourseName>
            </CourseInfo>
            <CertificationDetails>
              <CompletionDate>Complété le {certification.date}</CompletionDate>
              <DownloadButton>
                Télécharger <DownloadIcon />
              </DownloadButton>
            </CertificationDetails>
          </CertificationItem>
        ))}
      </CertificationList>
    </CertificationsContainer>
  );
};

export default Certifications;
