import React from 'react';
import CTA from './CtaSection';
import Faq from './FaqSection';
import About from '../../pages/home-4/AboutSection';
import Courses from '../home/CoursesSection';
import Categories from '../../pages/home-4/CategoriesSection';
import Testimonial from '../../pages/home-4/TestimonialSection';
import ScrollToTop from '../../components/Common/ScrollTop';
import BannerDefault from '../../components/Banner/BannerDefault';
import { fetchCategoryForHomePage } from 'services/categorieCourses';


const HomeTwoMain = () => {
	const [categories, setCategories] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    const loadCategories = async () => {
        try {
            const response = await fetchCategoryForHomePage();
			console.log({ response });
            setCategories(response.data.data);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }

    React.useEffect(() => {
        loadCategories().then(r => console.log('Categories loaded'));
    }
    , [])

        console.log(categories)
	return (
		<React.Fragment>
			{/* Banner-area-start */}
			<BannerDefault />
			{/* Banner-area-end */}

			{/* about-area-start */}
			<About />
			{/* about-area-end */}

			{/* Categories-area-start */}
			<Categories categories={categories}/>
			{/* Categories-area-end */}

			{/* Course-area-start */}
			<Courses />
			{/* Course-area-end */}

			{/* call us section start */}
			<CTA />
			{/* call us section end */}

			{/* Faq section start */}
			<Faq />
			{/* Faq section end */}

			{/* Team section start
			<Team />
			 Team section end */}

			{/* testmonial-area-start */}
			<Testimonial />
			{/* testmonial-area-end */}

			{/* blog-area-start
			<Blog />
			 blog-area-end */}

			{/* scrolltop-start */}
			<ScrollToTop />
			{/* scrolltop-end */}
		</React.Fragment>
	);
}

export default HomeTwoMain;