import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Styled-components pour les cartes
const Card = styled.div`
  background-color: #fefefe;
  //border-radius: 10px;
  text-align: center;
  padding: 20px;
  //box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center; /* Alignement horizontal */
  justify-content: space-between; /* Espacement entre les parties */
  height: 200px; /* Hauteur uniforme */

  @media (max-width: 768px) {
    height: auto; /* Ajustement pour mobiles */
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center; /* Centre verticalement */
  justify-content: center; /* Centre horizontalement */
  width: 60px; /* Taille du conteneur */
  height: 60px;
  background-color: rgba(156, 22, 35, 0.1); /* Ajout d'un fond subtile */
  border-radius: 50%;
  margin-bottom: 20px;
`;

const Icon = styled.img`
  width: 50%;
  height: 50%;
  object-fit: contain; /* Ajuste l'image sans déformation */
`;

const Title = styled.h4`
  font-size: 15px;
  color: #333;
  margin-bottom: 15px;
  text-align: center;
`;

const CourseCount = styled(Link)`
  font-size: 14px;
  color: #000000;
  //font-weight: bold;
  text-decoration: none;

  &:hover {
    color: #9c1623;
  }
`;

const CategoriesSingleThree = ({ categoriesClass, iconImg, title, courseQuantity,categoryId }) => {
  return (
    <Card className={categoriesClass}>
      <IconWrapper>
        <Icon src={iconImg} alt={title} />
      </IconWrapper>
      <Title>{title}</Title>
      <CourseCount to={`/course-categories/${categoryId}`}>
        {courseQuantity} cours &nbsp; <i className="fa fa-arrow-right"></i>
      </CourseCount>
    </Card>
  );
};

export default CategoriesSingleThree;
