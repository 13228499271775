import React, { useState } from 'react';
import styled from 'styled-components';
import SectionTitle from '../../components/Common/SectionTitle';
import ModalVideo from 'react-modal-video';
import { Link } from "react-router-dom";

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

const Faq = () => {
    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => setIsOpen(!isOpen);

    return (
        <div className='mb-100 mt-100'>
            <ModalVideo channel='youtube' isOpen={isOpen} videoId='YOUR_VIDEO_ID' onClose={openModal} />
            <div className="container" style={{ maxWidth: '80%', margin: '0 auto' }}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="main-part">
                            <SectionTitle
                                sectionClass="sec-title"
                                subtitleClass="sub-title primary"
                                subtitle={<div>FAQ</div>}
                                titleClass="title mt-0"
                                title="Foire Aux Questions"
                                descClass="desc big"
                                description={
                                    <div style={{ fontFamily: "'MetropolisMedium', sans-serif", fontStyle: "normal", fontWeight: "400", fontSize: "16px", lineHeight: "145%", marginTop: "10px" }}>
                                      Bienvenue dans notre Foire Aux Questions ! Nous avons répondu aux questions que vous nous posez régulièrement ! Si vous avez d’autres interrogations, n'hésitez pas à nous contacter via notre page <Link to="/contact">Contact</Link>.
                                      <br />
                                    </div>
                                  }
                                  
                            />
                            <div className="faq-content">
                                <Accordion className="accordion-style1" preExpanded={['a']}>
                                    <AccordionItem className="accordion-item" uuid="a">
                                        <AccordionItemHeading className="card-header">
                                            <AccordionItemButton className="accordion-button">
                                            Quels sont les avantages à suivre vos formations ?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel className="card-body">
                                        Nos formations en ligne vous permettent d'apprendre à votre rythme, où que vous soyez et à n'importe quel moment. Elles offrent une grande flexibilité, vous donnant accès à des contenus actualisés et à des experts et pratiquants dans divers domaines.
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem className="accordion-item" uuid="b">
                                        <AccordionItemHeading className="card-header">
                                            <AccordionItemButton className="accordion-button">
                                            Les cours sont-ils accessibles sur mobile ?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel className="card-body">
                                        Oui, nos cours sont entièrement accessibles sur mobile, tablette et ordinateur. Vous pouvez suivre vos modules de formation depuis n'importe quel appareil connecté, ce qui vous permet de continuer à apprendre même en déplacement.
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem className="accordion-item" uuid="c">
                                        <AccordionItemHeading className="card-header">
                                            <AccordionItemButton className="accordion-button">
                                            Proposez-vous des certifications à la fin des cours ?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel className="card-body">
                                        Absolument ! À la fin de chaque formation, vous recevrez une attestation. Puis, à votre demande, nous pouvons vous accompagner pour l’obtention d’une ou de plusieurs certifications officielles relatives à la formation suivie.
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem className="accordion-item" uuid="d">
                                        <AccordionItemHeading className="card-header">
                                            <AccordionItemButton className="accordion-button">
                                            Puis-je avoir un aperçu des cours avant de m'inscrire ?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel className="card-body">
                                        Oui, nous proposons des aperçus gratuits pour certains cours afin que vous puissiez vous faire une idée de leur contenu et de la qualité des enseignements. Vous pouvez ainsi explorer les grandes lignes du programme avant de prendre votre décision.
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem className="accordion-item" uuid="e">
                                        <AccordionItemHeading className="card-header">
                                            <AccordionItemButton className="accordion-button">
                                            Quel est le niveau requis pour suivre vos formations ? 
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel className="card-body">
                                        Nos formations s'adressent à tous les niveaux, que vous soyez débutant, intermédiaire ou avancé. Chaque cours est accompagné d'une description détaillant le niveau requis et les prérequis nécessaires pour en tirer le meilleur parti.
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem className="accordion-item" uuid="f">
                                        <AccordionItemHeading className="card-header">
                                            <AccordionItemButton className="accordion-button">
                                            Les cours sont-ils mis à jour régulièrement ?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel className="card-body">
                                        Oui, nos cours sont régulièrement mis à jour. Nous nous assurons que vous disposez toujours des connaissances les plus pertinentes pour votre secteur d’activité.
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem className="accordion-item" uuid="g">
                                        <AccordionItemHeading className="card-header">
                                            <AccordionItemButton className="accordion-button">
                                            Comment puis-je accéder aux cours après l'inscription ?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel className="card-body">
                                        Après votre inscription, vous pouvez accéder à vos cours en vous connectant à votre compte sur notre plateforme. Tous les cours sont disponibles en ligne, et vous pouvez les suivre à votre propre rythme.
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem className="accordion-item" uuid="h">
                                        <AccordionItemHeading className="card-header">
                                            <AccordionItemButton className="accordion-button">
                                            Puis-je obtenir une attestation à la fin du cours ?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel className="card-body">
                                        Oui ! Nos cours offrent des attestations de réussite. Vous recevrez une attestation dès que vous aurez complété toutes les exigences du cours, généralement sous la forme de projets pratiques, d'examens ou de quizzes.
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem className="accordion-item" uuid="i">
                                        <AccordionItemHeading className="card-header">
                                            <AccordionItemButton className="accordion-button">
                                            Quels types de supports sont proposés dans les cours ? 
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel className="card-body">
                                        Nous offrons une variété de supports pour enrichir votre apprentissage. Chaque cours inclut des vidéos explicatives, des PDF téléchargeables pour approfondir les notions clés, ainsi que des ressources supplémentaires comme des études de cas, des quiz interactifs et des exercices pratiques. Ces outils sont conçus pour vous aider à maîtriser les concepts à votre rythme et selon vos préférences.
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem className="accordion-item" uuid="j">
                                        <AccordionItemHeading className="card-header">
                                            <AccordionItemButton className="accordion-button">
                                            Les cours sont-ils accessibles aux apprenants internationaux ?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel className="card-body">
                                        Absolument. Nos cours en ligne sont accessibles à des apprenants du monde entier. Nous proposons des contenus multilingues et encourageons la diversité dans notre communauté éducative.
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                </Accordion>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Faq;
