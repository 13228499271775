import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FaRegClock, FaCertificate, FaTimes } from 'react-icons/fa';
import { CartContext } from './CartContext';
import shopImg1 from '../../assets/img/shop/1.jpg';

const MainContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Roboto', Arial, sans-serif;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const CartHeader = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #2c3e50;

  @media (max-width: 576px) {
    font-size: 2rem;
    text-align: center;
  }
`;

const CartSubHeader = styled.h2`
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: #2c3e50;

  @media (max-width: 576px) {
    font-size: 1rem;
    text-align: center;
  }
`;

const CartLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const CartItems = styled.div`
  flex: 3;
  padding-right: 20px;

  @media (max-width: 768px) {
    padding-right: 0;
  }
`;

const CartItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ecf0f1;
  padding: 20px 0;

  @media (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ItemInfo = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;

  @media (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ItemImage = styled.img`
  width: 120px;
  height: 80px;
  object-fit: cover;
  margin-right: 20px;
  border-radius: 8px;

  @media (max-width: 576px) {
    width: 100%;
    height: auto;
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

const ItemDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemTitle = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  color: #34495e;
  margin-bottom: 5px;

  @media (max-width: 576px) {
    font-size: 1rem;
  }
`;

const ItemAuthor = styled.div`
  font-size: 0.9rem;
  color: #95a5a6;
  margin-bottom: 10px;
`;

const ItemAttributes = styled.div`
  display: flex;
  font-size: 0.9rem;
  color: #7f8c8d;

  & > div {
    margin-right: 15px;
    display: flex;
    align-items: center;
  }

  svg {
    margin-right: 5px;
  }

  @media (max-width: 576px) {
    flex-wrap: wrap;
    & > div {
      margin-bottom: 10px;
    }
  }
`;

const ItemPriceContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 576px) {
    align-self: flex-start;
    margin-top: 10px;
  }
`;

const ItemPrice = styled.div`
  font-size: 1.2rem;
  color: #2c3e50;
  font-weight: bold;
  margin-right: 10px;

  @media (max-width: 576px) {
    font-size: 1rem;
  }
`;

const RemoveButton = styled.button`
  background: none;
  border: none;
  color: #2c3e50;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    color: #c0392b;
  }
`;

const CartSummary = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: -60px;

  @media (max-width: 768px) {
    margin-top: 20px;
  }

  @media (max-width: 576px) {
    margin-top: 10px;
    padding: 15px;
  }
`;

const SummaryHeader = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #2c3e50;
  text-align: left;
  width: 100%;

  @media (max-width: 576px) {
    text-align: center;
  }
`;

const SummaryTotal = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 1.2rem;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 20px;

  @media (max-width: 576px) {
    flex-direction: column;
    align-items: center;
  }
`;

const CheckoutButton = styled.button`
  background-color: #a60b0b;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1.2rem;
  margin-bottom: 20px;
  width: 100%;

  &:hover {
    background-color: #8b0000;
  }
`;

const PaymentIcons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;

  img {
    margin: 0 10px;

    @media (max-width: 576px) {
      width: 40px;
      margin: 5px;
    }
  }
`;

const PaymentInfo = styled.div`
  text-align: center;
  font-size: 0.9rem;
  color: #7f8c8d;
  margin-top: 20px;
`;


const RecommendedSection = styled.div`
  margin-top: 40px;

  @media (max-width: 576px) {
    margin-top: 20px;
  }
`;

const RecommendedTitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #2c3e50;

  @media (max-width: 576px) {
    font-size: 1.5rem;
  }
`;


function CartPage() {
  const { cartItems, removeFromCart } = useContext(CartContext); // Utilisez removeFromCart
  const navigate = useNavigate();

  const total = cartItems.reduce((sum, item) => sum + item.price, 0);

  return (
    <MainContainer>
      <CartHeader>Mon panier</CartHeader>
      <CartSubHeader>Panier ({cartItems.length} cours) • {total.toLocaleString()} {cartItems[0]?.devise || 'Devise'}</CartSubHeader>
      <CartLayout>
        <CartItems>
          {cartItems.length === 0 ? (
            <p>Votre panier est vide.</p>
          ) : (
            cartItems.map(item => (
              <CartItem key={item.id}>
                <ItemInfo>
                  <ItemImage src={item.cover || shopImg1} alt={item.title || 'Image indisponible'} />
                  <ItemDetails>
                    <ItemTitle>{item.title || 'Titre indisponible'}</ItemTitle>
                    <ItemAuthor>
                      {item.category} • De {item.user?.full_name || 'Instructeur inconnu'}
                    </ItemAuthor>
                    <ItemAttributes>
                      <div><FaRegClock /> Durée : {item.duration || 'Non spécifiée'}</div>
                    </ItemAttributes>
                  </ItemDetails>
                </ItemInfo>
                <ItemPriceContainer>
                  <ItemPrice>{item.price?.toLocaleString() || '0'} {item.devise || 'Devise'}</ItemPrice>
                  <RemoveButton onClick={() => removeFromCart(item.id)}>
                    <FaTimes />
                  </RemoveButton>
                </ItemPriceContainer>
              </CartItem>
            ))
          )}
        </CartItems>
        <CartSummary>
          <SummaryHeader>Récapitulatif</SummaryHeader>
          <SummaryTotal>
  <div>Total de la commande</div>
  <div>{total.toLocaleString()} {cartItems[0]?.devise || 'Devise'}</div>
</SummaryTotal>

          <CheckoutButton onClick={() => navigate('/shop/checkout')}>
            Passer la commande
          </CheckoutButton>
          <PaymentInfo>
            Paiement sécurisé à 100%
            <br />
            Ce certificat garantit la sécurité de toutes vos connexions par cryptage.
            <PaymentIcons>
              <img src="https://via.placeholder.com/50x30" alt="MTN MoMo" />
              <img src="https://via.placeholder.com/50x30" alt="Orange Money" />
              <img src="https://via.placeholder.com/50x30" alt="Mastercard" />
              <img src="https://via.placeholder.com/50x30" alt="VISA" />
            </PaymentIcons>
          </PaymentInfo>
        </CartSummary>
      </CartLayout>
      <RecommendedSection>
        <RecommendedTitle>Ça pourrait vous intéresser</RecommendedTitle>
        {/* Section pour des cours recommandés */}
      </RecommendedSection>
    </MainContainer>
  );
}

export default CartPage;