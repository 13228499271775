import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import teacherService from '../../services/teacherService';

const Container = styled.div`
  padding: 40px;
  width: 78%;
  margin: 4rem auto;

  @media (max-width: 1024px) {
    width: 90%;
    padding: 20px;
  }

  @media (max-width: 768px) {
    margin: 2rem auto;
    padding: 10px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const TitleLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const TitleIndicator = styled.span`
  width: 25px;
  height: 2.4px;
  background-color: #ab1827;
  margin-right: 20px;
`;

const TitleLabel = styled.span`
  color: #ab1827;
  font-size: 14px;
  line-height: 16.8px;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
`;

const Title = styled.h2`
  font-size: 28px;
  font-weight: 700;
  line-height: 33.6px;
  letter-spacing: -1px;
  margin: 0;
  color: #000;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 30px;
  }

  @media (max-width: 480px) {
    font-size: 20px;
    line-height: 26px;
  }
`;

const Subtitle = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 23.2px;
  color: #101012;
  margin-top: 0.5rem;

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const InstructorsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

const InstructorCard = styled.div`
  text-align: left;
`;

const InstructorImage = styled.img`
  width: 100%;
  height: 180px;
  object-fit: cover;

  @media (max-width: 480px) {
    height: 150px;
  }
`;

const InstructorName = styled.h3`
  margin-top: 10px;
  font-size: 16px;
  font-weight: 600;
  line-height: 23.2px;
  margin-bottom: 5px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const InstructorRole = styled.p`
  color: #666;
  font-weight: 400;
  line-height: 20.3px;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 13px;
  }
`;

const ViewListButton = styled.button`
  background-color: transparent;
  border: 1.5px solid #9c1623;
  color: #9c1623;
  padding: 8px 16px;
  border-radius: 37px;
  cursor: pointer;
  font-size: 14px;
  line-height: 20.3px;
  font-weight: 600;
  min-width: 140px;
  white-space: nowrap;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #9c1623;
    color: #fff;
  }

  @media (max-width: 768px) {
    padding: 6px 12px;
    font-size: 13px;
  }

  @media (max-width: 480px) {
    width: 100%;
    text-align: center;
    padding: 10px 0;
    font-size: 14px;
  }
`;

const Instructors = () => {
  const [instructors, setInstructors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');


  const fetchInstructors = async () => {
    try {
      const data = await teacherService.fetchTeachers();
      console.log(data.data.users.data);
      setInstructors(data.data.users.data);
    } catch (err) {
      setError('Erreur lors du chargement des formateurs.');
    } finally {
      setLoading(false);
    }
  };

  

  useEffect(() => {
    fetchInstructors();
    
  }, []);

  if (loading) {
    return <p>Chargement des formateurs...</p>;
  }

  if (error) {
    return <p className="text-red-500">{error}</p>;
  }

  return (
    <Container>
      <Header>
        <TitleWrapper>
          <TitleLabelWrapper>
            <TitleIndicator />
            <TitleLabel>Nos formateurs</TitleLabel>
          </TitleLabelWrapper>
          <div>
            <Title>Découvrez nos formateurs</Title>
            <Subtitle>
              Nos formateurs sont des experts et des pratiquants reconnus dans leurs domaines respectifs, avec des années d'expérience professionnelle et pédagogique.
            </Subtitle>
          </div>
        </TitleWrapper>
        <ViewListButton>Voir la liste</ViewListButton>
      </Header>
      <InstructorsGrid>
        {instructors.map((instructor, index) => (
          <InstructorCard key={index}>
            <InstructorImage
              src={instructor.image || 'https://via.placeholder.com/180'} // Image par défaut si `image` est null
              alt={instructor.name}
            />
            <InstructorName>{instructor.nom}-{ instructor.prenom}</InstructorName>
            {/* <InstructorRole>{instructor.role}</InstructorRole> */}
          </InstructorCard>
        ))}
      </InstructorsGrid>
    </Container>
  );
};

export default Instructors;
