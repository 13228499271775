import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import { saveCategorieCourses } from '../../../services/categorieCourses';
import NotificationService from '../../../services/notification';
import Dropzone from './Dropzone';
import TextEditor from 'components/utilities/TextEditor';
import { getModuleByCategory } from 'services/module';
import s3 from '../../../services/awsConfig'

const StepContainer = styled.div`
  padding: 20px;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 15px;
  }

  @media (max-width: 576px) {
    padding: 10px;
    margin-left: 0;
    margin-right: 0;
  }
`;

const StepHeader = styled.h2`
  font-size: 24px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 8px;
  }

  @media (max-width: 576px) {
    font-size: 18px;
    margin-bottom: 6px;
  }
`;

const SubHeader = styled.p`
  margin-bottom: 20px;
  color: #666;

  @media (max-width: 768px) {
    margin-bottom: 16px;
  }

  @media (max-width: 576px) {
    margin-bottom: 12px;
  }
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;

  @media (max-width: 768px) {
    margin-bottom: 4px;
  }

  @media (max-width: 576px) {
    margin-bottom: 3px;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: #f5f5f5;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 8px;
    margin-bottom: 8px;
  }

  @media (max-width: 576px) {
    padding: 6px;
    margin-bottom: 6px;
  }
`;

const Select = styled.select`
  max-height: 200px; /* Limite la hauteur */
  overflow-y: auto; /* Ajoute un scroll si nécessaire */
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: #f5f5f5;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 8px;
    margin-bottom: 8px;
  }

  @media (max-width: 576px) {
    padding: 6px;
    margin-bottom: 6px;
  }
`;

const AddButton = styled.button`
  background: none;
  color: #888;
  border: none;
  cursor: pointer;
  font-size: 14px;
  margin: 10px 0;
  display: flex;
  align-items: center;

  &:hover {
    color: #000;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    margin: 8px 0;
  }

  @media (max-width: 576px) {
    font-size: 10px;
    margin: 6px 0;
  }
`;

const Section = styled.div`
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: #fff;

  @media (max-width: 768px) {
    margin-bottom: 16px;
    padding: 16px;
  }

  @media (max-width: 576px) {
    margin-bottom: 12px;
    padding: 12px;
    margin-left: 0;
    margin-right: 0;
  }
`;

const InlineButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    gap: 8px;
  }

  @media (max-width: 576px) {
    gap: 6px;
  }
`;

const StepTwo = ({ data, setData, categoriesCourse = [] }) => {
  const [categories, setCategories] = useState([]);
  const [modules, setModules] = useState(data.summary.modules || []);
  const [currentCategory, setCurrentCategory] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(data.summary.cartegory_id || '');
  const [showCategoryInput, setShowCategoryInput] = useState(false);
  const [currentModule, setCurrentModule] = useState('');
  const [showModuleInput, setShowModuleInput] = useState(false);
  const [currentChapter, setCurrentChapter] = useState('');
  const [showChapterInput, setShowChapterInput] = useState(false);
  const [newChapterModuleIndex, setNewChapterModuleIndex] = useState(null);
  const [modulesByCategory, setModulesByCategory] = useState([]);
  const [files,setFiles] = useState([])

  const handleAddCategory = async () => {
    if (currentCategory.trim() === '') {
      NotificationService.error("La catégorie ne peut pas être vide");
      return;
    }

    const newCategories = [...categories, currentCategory];
    setCategories(newCategories);
    setCurrentCategory('');
    setShowCategoryInput(false);

    try {
      const data = { name: currentCategory };
      const response = await saveCategorieCourses(data);
      if (response.data.success) {
        NotificationService.success("Catégorie ajoutée avec succès");
      } else {
        NotificationService.error("Erreur lors de l'ajout de la catégorie");
      }
    } catch (error) {
      console.error(error);
      NotificationService.error("Erreur lors de l'ajout de la catégorie");
    }
  };

  const handleGetModuleByCategory = useCallback(async () => {
    if (!selectedCategory) {
      console.error("Aucune catégorie sélectionnée");
      return;
    }
    try {
      const data = { category_id: selectedCategory };
      const response = await getModuleByCategory(data);
      if (response.data.success) {
        setModulesByCategory(response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [selectedCategory]);
  

  useEffect(() => {
    handleGetModuleByCategory();
  }, [selectedCategory, handleGetModuleByCategory]);

  const dataList = (
    <datalist id="data">
      {modulesByCategory.map((module, index) => (
        <option key={index} value={module.name} />
      ))}
    </datalist>
  );

  const uploadToS3 = (file) => {
    if (!(file instanceof Blob)) {
      file = new Blob([file]);
    }
    console.log(file)
    const fileNameWithoutSpaces = file.name.replace(/\s+/g, '-');

    const params = {
      Bucket: "akambi",
      Key: `${Date.now()}-${fileNameWithoutSpaces}`,
      Body: file,
    };

    return s3.upload(params).promise(); 
  };

  const handleFileUpload = async (file) => {
    try {
      const response = await uploadToS3(file);
      return response;
    } catch (error) {
      console.log('Erreur lors du téléchargement du fichier sur S3:', error);
      throw error;
    }
  };


  const handleAddModule = () => {
    if (currentModule.trim() === '') {
      NotificationService.error("Le module ne peut pas être vide");
      return;
    }

    const newModule = { id: modules.length + 1, name: currentModule, chapters: [] };
    setModules([...modules, newModule]);
    setCurrentModule('');
    setShowModuleInput(false);
  };

  const handleAddChapter = (moduleIndex) => {
    if (currentChapter.trim() === '') {
      NotificationService.error("Le chapitre ne peut pas être vide");
      return;
    }

    const newModules = [...modules];
    newModules[moduleIndex].chapters.push({ id: newModules[moduleIndex].chapters.length + 1, title: currentChapter, video: '' });
   
    setData({ ...data, summary: { cartegory_id: selectedCategory, modules: newModules } });
    setModules(newModules);
    setCurrentChapter('');
    setShowChapterInput(false);
    setNewChapterModuleIndex(null);
  };

  const handleInputChange = (value, moduleIndex, chapterIndex, field) => {
    const newModules = [...modules];
    newModules[moduleIndex].chapters[chapterIndex][field] = value;
    setData({ ...data, summary: { cartegory_id: selectedCategory, modules: newModules } });
    setModules(newModules);
  };

  const handleRemoveChapter = (moduleIndex, chapterIndex) => {
    const newModules = [...modules];
    newModules[moduleIndex].chapters.splice(chapterIndex, 1);
    setModules(newModules);
  };

  const onDrop = useCallback(async (acceptedFiles, moduleIndex, chapterIndex) => {
    const newModules = [...modules];
    newModules[moduleIndex].chapters[chapterIndex].video = acceptedFiles[0].name;
    
    try {
      const response = await handleFileUpload(acceptedFiles[0]);
      newModules[moduleIndex].chapters[chapterIndex].file = response.Location;
    } catch (error) {
      console.error('Erreur lors du téléchargement du fichier:', error);
    }
  
    setData({ ...data, summary: { cartegory_id: selectedCategory, modules: newModules } });
    setModules(newModules);
  }, [modules, data, selectedCategory, setData]);

  
  return (
    <StepContainer>
      <StepHeader>Contenu de votre cours</StepHeader>
      <SubHeader>Phasellus enim magna, varius et commodo ut, ultricies vitae velit.</SubHeader>
      <Section>
  <Label>Catégorie</Label>
        <Select
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
        >
          <option value="">Choisir la catégorie</option>
          {categoriesCourse.map((category) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
        </Select>
        {showCategoryInput && (
          <Input
            type="text"
            value={currentCategory}
            onChange={(e) => setCurrentCategory(e.target.value)}
            placeholder="Nouvelle catégorie"
            onBlur={handleAddCategory}
          />
        )}
        <InlineButtonContainer>
          <AddButton onClick={() => setShowCategoryInput(true)}>
            + Ajouter une catégorie
          </AddButton>
        </InlineButtonContainer>
      </Section>


      {modules.map((module, moduleIndex) => (
        <Section key={module.id}>
          <Label>Module {moduleIndex + 1}</Label>
          <Input
            type="text"
            list="data"
            value={module.name}
            onChange={(e) => setModules(prevModules => {
              const newModules = [...prevModules];
              newModules[moduleIndex].name = e.target.value;
              return newModules;
            })}
            placeholder="Titre du module"
          />
          {dataList}
          {module.chapters.map((chapter, chapterIndex) => (
            <div key={chapter.id} style={{ marginBottom: '10px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Input
                  type="text"
                  value={chapter.title}
                  onChange={(e) => handleInputChange(e.target.value, moduleIndex, chapterIndex, 'title')}
                  placeholder={`Chapitre ${chapterIndex + 1}`}
                  style={{ flexGrow: 1 }}
                />
                <AddButton onClick={() => handleRemoveChapter(moduleIndex, chapterIndex)}>
                  <FaTimes />
                </AddButton>
              </div>
              <TextEditor value={chapter.contenu} onChange={(e) => handleInputChange(e, moduleIndex, chapterIndex, 'contenu')} />
              <Dropzone
                title={chapter.video ? chapter.video : "Ajouter une vidéo"}
                style={{ marginBottom: '10px' }}
                onDrop={(acceptedFiles) => onDrop(acceptedFiles, moduleIndex, chapterIndex)}
              />
            </div>
          ))}
          {showChapterInput && newChapterModuleIndex === moduleIndex && (
            <div>
              <Input
                type="text"
                value={currentChapter}
                onChange={(e) => setCurrentChapter(e.target.value)}
                placeholder="Chapitre"
                style={{ marginBottom: '10px' }}
                onBlur={() => handleAddChapter(moduleIndex)}
              />
              <TextEditor />
              <Dropzone
                title="Ajouter une vidéo"
                style={{ marginBottom: '10px' }}
                onDrop={(acceptedFiles) => onDrop(acceptedFiles, moduleIndex, modules[moduleIndex].chapters.length)}
              />
            </div>
          )}
          <InlineButtonContainer>
            <AddButton onClick={() => {
              setShowChapterInput(true);
              setNewChapterModuleIndex(moduleIndex);
            }}>
              + Ajouter un chapitre
            </AddButton>
          </InlineButtonContainer>
        </Section>
      ))}

      <InlineButtonContainer>
        <AddButton onClick={() => setShowModuleInput(true)}>
          + Ajouter un module
        </AddButton>
      </InlineButtonContainer>
      {showModuleInput && (
        <Section>
          <Input
            type="text"
            value={currentModule}
            onChange={(e) => setCurrentModule(e.target.value)}
            placeholder="Nouveau module"
            onBlur={handleAddModule}
          />
        </Section>
      )}
    </StepContainer>
  );
};

export default StepTwo;