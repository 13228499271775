import React, { useEffect, useState, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import styled from 'styled-components';
import { getCourseDetail } from '../../../services/course'; // Importation de la fonction API
import { CartContext } from 'pages/shop/CartContext';

import { Helmet } from 'react-helmet';
import Header from '../../../components/Layout/Header/Header';
import Footer from '../../../components/Layout/Footer/Footer';
import OffWrap from '../../../components/Layout/Header/OffWrap';
import SearchModal from '../../../components/Layout/Header/SearchModal';
import ScrollToTop from '../../../components/Common/ScrollTop';

import favIcon from '../../../assets/img/fav-orange.png';
import Logo from '../../../assets/img/logo/dark-logo.png';
import footerLogo from '../../../assets/img/logo/lite-logo.png';

import { Clock, Award, Star, Play, CheckCircle2, Circle } from 'lucide-react';

const AddToCartButton = styled.button`
  background-color: #79111C;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 8px;
  font-size: 1rem;

  @media (max-width: 576px) {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
`;

const StyledLinkButton = styled(Link)`
  background-color: #79111C;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 8px;
  font-size: 1rem;
  text-decoration: none;
  display: inline-block;
  text-align: center;

  @media (max-width: 576px) {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
`;

// Données statiques en cas d'erreur
const defaultInstructorImage = "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?w=400&h=400&fit=crop";

const CourseDetail = () => {
  const { id } = useParams();
  const [courseData, setCourseData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { addToCart, isInCart } = useContext(CartContext);

  const tabs = [
    { id: 1, label: "A propos" },
    { id: 2, label: "Curriculum" },
    { id: 3, label: "Instructeur" },
    { id: 4, label: "Faq" },
    { id: 5, label: "Ressources" },
    { id: 6, label: "Du même auteur" }
  ];
  
  const [activeTab, setActiveTab] = useState(1); // Par défaut, l'onglet "A propos" est actif

  useEffect(() => {
    getCourseDetail(id)
      .then(response => {
        setCourseData(response.data);
        setLoading(false);
      })
      .catch(err => {
        console.error('Erreur lors de la récupération des détails du cours', err);
        setError('Erreur lors du chargement du cours');
        setLoading(false);
      });
  }, [id]);

  const handleAddToCart = () => {
    addToCart(courseData);
  };

  if (loading) {
    return <div>Chargement...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="min-h-screen bg-white">
      {/* Helmet to manage favicon */}
      <Helmet>
        <link rel="icon" href={favIcon} />
      </Helmet>

      {/* Off-canvas and header */}
      <OffWrap />
      <Header
        parentMenu=""
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
        CanvasLogo={Logo}
        mobileNormalLogo={Logo}
        CanvasClass="right_menu_togle hidden-md"
        headerClass="full-width-header header-style1 home8-style4"
      />

      {/* Breadcrumb */}
      <div className="max-w-7xl mx-auto px-4 py-3 flex items-center space-x-2 text-sm text-gray-500">
        <span className="hover:text-gray-700 cursor-pointer">Lorem</span>
        <span>›</span>
        <span className="hover:text-gray-700 cursor-pointer">Lorem</span>
        <span>›</span>
        <span className="text-gray-900">Lorem</span>
      </div>

      <div className="max-w-7xl mx-auto px-4">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 items-start">
          {/* Main Content */}
          <div className="lg:col-span-2">
            {/* Course Image */}
            <div className="relative aspect-video rounded-lg overflow-hidden mb-6">
              <img
                src={courseData.cover}
                alt={courseData.title}
                className="w-full h-full object-cover"
              />
              <button className="absolute inset-0 bg-opacity-40 flex items-center justify-center group">
                <Play className="w-16 h-16 text-white opacity-80 group-hover:opacity-100 transition-opacity" />
              </button>
            </div>

            {/* Tabs */}
            <div className="border-b border-gray-200">
              <nav className="-mb-px flex space-x-8">
                {tabs.map(tab => (
                  <button
                    key={tab.id}
                    onClick={() => setActiveTab(tab.id)}
                    className={`
                      whitespace-nowrap py-4 px-1 text-sm font-medium border-b-2 
                      ${activeTab === tab.id
                        ? 'border-red-600 text-red-600'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}`}
                  >
                    {tab.label}
                  </button>
                ))}
              </nav>
            </div>

            {/* Course Content */}
            <div className="py-8">
              {activeTab === 1 && (
                <div>
                  <h2 className="text-2xl font-medium text-gray-900 mb-6">{courseData.title}</h2>
                  <p className="text-gray-600 mb-6 leading-relaxed">
                    {courseData.description || "Description non disponible."}
                  </p>

                  <div className="flex flex-wrap gap-8 mb-8 text-sm">
                    <div className="text-gray-600">{courseData.total_students ? courseData.total_students.toLocaleString() : 'Données non disponibles'} Total Students</div>
                    <div className="flex items-center">
                      <span className="text-gray-600">{courseData.rating}</span>
                      <div className="flex text-yellow-400 mx-1">{'★'.repeat(5)}</div>
                      <span className="text-gray-600">({courseData.reviews_count} Rating)</span>
                    </div>
                    <div className="text-gray-600">{courseData.reviews} Reviews</div>
                  </div>

                  <div className="mb-8">
                    <h3 className="text-lg font-medium text-gray-900 mb-4">What you'll learn?</h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                      {Array.isArray(courseData.what_you_learn) && courseData.what_you_learn.length > 0 ? (
                        courseData.what_you_learn.map((item, index) => (
                          <div key={index} className="flex items-start">
                            <span className="text-red-600 mr-2 mt-1.5">•</span>
                            <span className="text-gray-600 leading-relaxed">{item}</span>
                          </div>
                        ))
                      ) : (
                        <p className="text-gray-600">Aucun élément trouvé.</p>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {activeTab === 2 && (
                <div>
                  {/* Curriculum Section */}
                  <h3 className="text-lg font-medium text-gray-900 mb-4">Curriculum</h3>
                  {/* Ici vous pouvez afficher le contenu du curriculum en fonction de `courseData.curriculum` */}
                </div>
              )}

              {activeTab === 3 && (
                <div>
                  {/* Instructor Info */}
                  <h3 className="text-lg font-medium text-gray-900 mb-4">Instructeur</h3>
                  <div className="flex items-center space-x-3 mb-6">
                    <img
                      src={courseData.instructor?.image || defaultInstructorImage}
                      alt={courseData.instructor?.name || "Instructeur non disponible"}
                      className="w-12 h-12 rounded-full"
                    />
                    <div className="text-sm">
                      <span className="text-gray-600">Un cours de </span>
                      <span className="text-red-600 font-medium">{courseData.instructor?.name || "Instructeur non disponible"}</span>
                    </div>
                  </div>
                </div>
              )}

              {activeTab === 4 && (
                <div>
                  {/* Faq Section */}
                  <h3 className="text-lg font-medium text-gray-900 mb-4">Faq</h3>
                  {/* Ici vous pouvez afficher le contenu de Faq en fonction de `courseData.faq` */}
                </div>
              )}

              {activeTab === 5 && (
                <div>
                  {/* Ressources Section */}
                  <h3 className="text-lg font-medium text-gray-900 mb-4">Ressources</h3>
                  {/* Ici vous pouvez afficher le contenu de Ressources en fonction de `courseData.ressources` */}
                </div>
              )}

              {activeTab === 6 && (
                <div>
                  {/* Du même auteur Section */}
                  <h3 className="text-lg font-medium text-gray-900 mb-4">Du même auteur</h3>
                  {/* Ici vous pouvez afficher le contenu de Du même auteur en fonction de `courseData.author` */}
                </div>
              )}

            </div>
          </div>

          {/* Sidebar */}
          <div className="lg:col-span-1 self-start mt-0">
            <div className="bg-white p-6">
              <h1 className="text-3xl font-bold text-gray-900 mb-4">{courseData.title}</h1>

              {/* Instructor Info */}
              <div className="flex items-center space-x-3 mb-6">
                <img
                  src={courseData.instructor?.image || defaultInstructorImage}
                  alt={courseData.instructor?.name || "Instructeur non disponible"}
                  className="w-12 h-12 rounded-full"
                />
                <div className="text-sm">
                  <span className="text-gray-600">Un cours de </span>
                  <span className="text-red-600 font-medium">{courseData.instructor?.name || "Instructeur non disponible"}</span>
                </div>
              </div>

              {/* Price and Add to Cart Button */}
              <div className="flex justify-between items-center mb-8">
                <span className="text-3xl font-bold">{courseData.price.toFixed(2).replace('.', ',')} €</span>

              {isInCart(courseData?.id) ? (
              <StyledLinkButton to="/shop/cart">Voir le panier</StyledLinkButton>
            ) : (
              <AddToCartButton onClick={handleAddToCart}>Ajouter au panier</AddToCartButton>
            )}
              </div>

              {/* Course Features */}
              <div className="flex items-center space-x-6 py-6 border-t border-b border-gray-100">
                <div className="flex items-center space-x-2">
                  <Clock className="w-5 h-5 text-gray-400" />
                  <span className="text-sm text-gray-600">{courseData.duration}</span>
                </div>
                <div className="flex items-center space-x-2">
                  <Award className="w-5 h-5 text-gray-400" />
                  <span className="text-sm text-gray-600">Certificat</span>
                </div>
                <div className="flex items-center space-x-1">
                  <span className="text-sm text-gray-600">{courseData.rating}</span>
                  <div className="flex text-yellow-400">{'★'.repeat(5)}</div>
                </div>
            </div>

             {/* Course Content */}
             <div className="mt-6">
                    <h3 className="font-medium text-gray-900 mb-4">Contenu</h3>
                    <ul className="space-y-4">
                      {Array.isArray(courseData.content) && courseData.content.length > 0 ? (
                        courseData.content.map((item, index) => (
                          <li key={index} className="flex justify-between items-center group">
                            <div className="flex items-center space-x-3">
                              {item.active ? (
                                <CheckCircle2 className="w-5 h-5 text-red-600" />
                              ) : (
                                <Circle className="w-5 h-5 text-gray-300 group-hover:text-gray-400" />
                              )}
                              <span className={`text-sm ${item.active ? 'text-red-600' : 'text-gray-600'}`}>
                                {item.title}
                              </span>
                            </div>
                            <span className="text-sm text-gray-500">{item.duration}</span>
                          </li>
                        ))
                      ) : (
                        <p className="text-gray-600">Aucun contenu disponible.</p>
                      )}
                    </ul>
                  </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer footerClass="rs-footer home9-style main-home" footerLogo={footerLogo} />
      <OffWrap />
      <SearchModal />
      <ScrollToTop />
    </div>
  );
};

export default CourseDetail;
