import React from 'react';
import { Link } from 'react-router-dom';
import bgImg from '../../assets/img/bg/home1.jpg';

const SliderDefault = () => {
  return (
    <div className="bg-white w-full">
      {/* Première section avec texte et bouton */}
      <div className="flex flex-col items-center justify-center py-20 bg-white px-4">
        <h1 className="text-4xl sm:text-5xl font-extrabold text-black mb-4 text-center leading-tight">
          Explorez l'apprentissage <br className="hidden sm:block" /> sans frontières
        </h1>
        <p className="text-base sm:text-lg md:text-xl text-gray-600 mb-8 text-center leading-relaxed">
          Découvrez une expérience d'apprentissage <br className="hidden sm:block" />
          à distance enrichissante et adaptable.
        </p>
        <Link
          to="/course"
          className="text-white text-sm sm:text-lg font-medium py-3 px-6 sm:px-8 rounded-full"
          style={{ backgroundColor: '#9c1623', hover: { backgroundColor: '#7d121d' } }}
        >
          Explorer nos cours
        </Link>
      </div>

      {/* Deuxième section avec l'image */}
      <div className="flex justify-center mt-12 px-4">
        <img
          src={bgImg}
          alt="Students learning"
          className="rounded-[24px] w-full max-w-[1200px] h-[448px] object-cover"
        />
      </div>
    </div>
  );
};

export default SliderDefault;
