import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import CategoriesSingleThree from '../../components/Categories/CategoriesSingleThree';
import { fetchCategoryForHomePage, getCategory } from 'services/categorieCourses';

import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer/Footer';
import OffWrap from '../../components/Layout/Header/OffWrap';
import SearchModal from '../../components/Layout/Header/SearchModal';
import ScrollToTop from '../../components/Common/ScrollTop';

import favIcon from '../../assets/img/fav-orange.png';
import Logo from '../../assets/img/logo/dark-logo.png';
import footerLogo from '../../assets/img/logo/lite-logo.png';
import icon1 from '../../assets/img/categories/icons/1.png';

// Styled-components
const CategoriesContainer = styled.div`
  position: relative;
  background-color: rgba(247, 232, 233, 0.4);
  padding: 94px 20px 70px;

  @media (max-width: 768px) {
    padding: 64px 10px 40px;
  }
`;

const CategoriesContent = styled.div`
  width: 80%;
  margin: 0 auto;
`;

const CategoriesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
`;

const AllCategories = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch categories on component mount
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const data = await getCategory();
        console.log('data:', data);
        setCategories(data.data.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      } finally {
        setLoading(false);
      }
    };

  fetchCategories();
  }, []);

  return (
    <React.Fragment>
      {/* Helmet to manage favicon */}
      <Helmet>
        <link rel="icon" href={favIcon} />
      </Helmet>

      {/* Off-canvas and header */}
      <OffWrap />
      <Header
        parentMenu=""
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
        CanvasLogo={Logo}
        mobileNormalLogo={Logo}
        CanvasClass="right_menu_togle hidden-md"
        headerClass="full-width-header header-style1 home8-style4"
      />

      <CategoriesContainer>
        <CategoriesContent>
          {loading ? (
            <p>Loading categories...</p>
          ) : categories.length > 0 ? (
            <CategoriesGrid>
              {categories.map((categorie, i) => (
                <CategoriesSingleThree
                  key={i}
                  categoriesClass="subject-wrap bgc5"
                  title={categorie.name}
                  courseQuantity={categorie.courses_count}
                  iconImg={icon1}
                />
              ))}
            </CategoriesGrid>
          ) : (
            <p>No categories found.</p>
          )}
        </CategoriesContent>
      </CategoriesContainer>

      {/* Footer */}
      <Footer footerClass="rs-footer home9-style main-home" footerLogo={footerLogo} />
      <SearchModal />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default AllCategories;
