import { API_BASE_URL} from ".";
import axios from "axios";

const sendContactMessage = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/contact`, formData);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

export default {
  sendContactMessage,
};
