import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import teacherService from "services/teacherService";

const AboutSection = styled.section`
  width: 90%;
  margin: 4rem auto;
  background-color: #ffffff;

  @media (min-width: 1024px) {
    width: 70%;
  }
`;

const AboutContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const AboutTextContainer = styled.div`
  flex: 1;
  max-width: 100%;

  @media (min-width: 768px) {
    max-width: 45%;
  }
`;

const TitleMarker = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const MarkerLine = styled.div`
  width: 25px;
  height: 2.4px;
  background-color: #ab1827;
  margin-right: 20px;
`;

const SectionLabel = styled.span`
  color: #ab1827;
  font-size: 14px;
  line-height: 16.8px;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
`;

const AboutTitle = styled.h2`
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -1px;
  margin: 0;
  color: #000;

  @media (min-width: 768px) {
    font-size: 28px;
    line-height: 33.6px;
    letter-spacing: -2px;
  }
`;

const AboutSubtitle = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #101012;
  margin-top: 0.5rem;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 23.2px;
  }
`;

const DetailedText = styled.div`
  flex: 1;
  font-weight: 400;
  font-size: 14px;
  color: #101012;
  line-height: 1.8;
  max-width: 100%;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 2;
    max-width: 60%;
  }
`;

const StatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 2rem;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 3rem;
    gap: 3rem;
  }
`;

const Stat = styled.div`
  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
  }
`;

const StatNumber = styled.div`
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -2px;
  color: #000;

  @media (min-width: 768px) {
    font-size: 56px;
    line-height: 56px;
    letter-spacing: -4px;
  }
`;

const StatLabel = styled.div`
  font-size: 14px;
  color: #666;

  @media (min-width: 768px) {
    font-size: 1rem;
  }
`;

const About = () => {
  const [loading, setLoading] = useState(true);
  const [countStart, setcountStat] = useState([]);
  const [error, setError] = useState("");

  const getCountStat = async () => {
    try {
      const data = await teacherService.getCountTeachers();
      console.log(data.data.users);
      setcountStat(data.data.users);
    } catch (err) {
      setError("Erreur lors du chargement des formateurs.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCountStat();
  }, []);
  return (
    <AboutSection>
      <AboutContent>
        <AboutTextContainer>
          <TitleMarker>
            <MarkerLine />
            <SectionLabel>A propos de nous</SectionLabel>
          </TitleMarker>
          <AboutTitle></AboutTitle>
          <AboutSubtitle>
            Bienvenue dans le monde de l'éducation sans frontières Où
            l'innovation, l'engagement et l'excellence convergent pour former le
            socle de la formation.
          </AboutSubtitle>
        </AboutTextContainer>
        <DetailedText>
          <p style={{ marginBottom: "10px" }}>
            <strong>Notre Mission :</strong>
            <br /> Notre mission est de libérer le potentiel éducatif de chaque
            individu, en offrant des programmes d'apprentissage à distance
            exceptionnels. Nous croyons en la puissance de l'éducation pour
            changer des vies et élargir les horizons, et nous nous engageons à
            fournir des ressources éducatives de qualité supérieure, où que vous
            soyez dans le monde.
          </p>
          <p style={{ marginBottom: "10px" }}>
            <strong>Notre Engagement envers l'excellence :</strong>
            <br /> Nous sommes animés par un engagement inébranlable envers
            l'excellence éducative. Nos instructeurs sont des experts passionnés
            dans leur domaine, guidant chaque étudiant à travers des cours
            conçus pour stimuler la réflexion critique, la créativité et la
            résolution de problèmes.
          </p>
          <p style={{ marginBottom: "10px" }}>
            <strong>Notre approche « Unique » :</strong>
            <br /> Ce qui nous distingue, c'est notre approche holistique de
            l'apprentissage, intégrant des interactions communautaires et des
            expériences pratiques pour une formation complète.
          </p>
          <p>
            <strong>L'Innovation au cœur de notre ADN :</strong>
            <br /> Dans un monde en constante évolution, nous embrassons
            l'innovation pour offrir un enseignement de pointe, préparant nos
            étudiants à exceller dans des environnements professionnels
            dynamiques.
          </p>
          <p style={{ marginBottom: "10px" }}>
            <strong>
              Votre aventure éducative commence{" "}
              <Link to="/login">ici</Link> :
            </strong>
            <br />
            <Link to="/login">Rejoignez-nous</Link> pour perfectionner vos
            compétences professionnelles, explorer de nouvelles passions ou
            poursuivre un diplôme — notre centre de formation est la passerelle
            vers un avenir éducatif exceptionnel.
          </p>
          <StatsContainer>
            <Stat>
              <StatNumber>{countStart.student}</StatNumber>
              <StatLabel>Apprenants</StatLabel>
            </Stat>
            <Stat>
              <StatNumber>{countStart.instructor}</StatNumber>
              <StatLabel>Enseignants actifs</StatLabel>
            </Stat>
            <Stat>
              <StatNumber>97%</StatNumber>
              <StatLabel>Taux d’achèvement</StatLabel>
            </Stat>
          </StatsContainer>
        </DetailedText>
      </AboutContent>
    </AboutSection>
  );
};

export default About;
