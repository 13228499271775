import 'tailwindcss/tailwind.css';
import React, { useState } from 'react';

const CategoryList = () => {
  const [activeMenu, setActiveMenu] = useState(null);
  const [showModal, setShowModal] = useState(false); // Gérer l'affichage du modal

  const categories = [
    { id: 1, code: 'CG', name: 'Comptabilité Gestion' },
    { id: 2, code: 'Code 2', name: 'Intitulé catégorie' },
    { id: 3, code: 'Code 2', name: 'Intitulé catégorie' },
    { id: 4, code: 'Code 2', name: 'Intitulé catégorie' },
    { id: 5, code: 'Code 2', name: 'Intitulé catégorie' },
    { id: 6, code: 'Code 2', name: 'Intitulé catégorie' },
    { id: 7, code: 'Code 2', name: 'Intitulé catégorie' },
    { id: 8, code: 'Code 2', name: 'Intitulé catégorie' },
    { id: 9, code: 'Code 2', name: 'Intitulé catégorie' },
    { id: 10, code: 'Code 2', name: 'Intitulé catégorie' },
  ];

  const handleMenuClick = (id) => {
    setActiveMenu(activeMenu === id ? null : id);
  };

  return (
    <div className="container mx-auto my-8">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold">Liste des catégories</h2>
        <button
          className="border border-red-600 text-red-600 px-4 py-2 rounded-lg hover:bg-red-50"
          onClick={() => setShowModal(true)} // Ouvrir le modal
        >
          Ajouter une catégorie
        </button>
      </div>

      {/* Table des catégories */}
      <div className="overflow-y-auto max-h-96">
        <table className="min-w-full bg-white border">
          <thead>
            <tr className="w-full bg-gray-100 text-left">
              <th className="p-4">#</th>
              <th className="p-4">Code</th>
              <th className="p-4">Intitulé</th>
              <th className="p-4">Actions</th>
            </tr>
          </thead>
          <tbody>
            {categories.map((category) => (
              <tr key={category.id} className="border-t">
                <td className="p-4">{category.id}</td>
                <td className="p-4">{category.code}</td>
                <td className="p-4">{category.name}</td>
                <td className="p-4">
                  <div className="relative">
                    <button className="text-gray-600 hover:text-black focus:outline-none" onClick={() => handleMenuClick(category.id)}>
                      ...
                    </button>
                    {activeMenu === category.id && (
                      <div className="absolute right-0 mt-2 w-48 bg-white border rounded-lg shadow-lg z-10">
                        <ul>
                          <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">Modifier</li>
                          <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">Supprimer</li>
                          <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">Désactiver</li>
                        </ul>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="flex justify-between items-center mt-4">
        <p>{categories.length} sur 500 catégories</p>
        <div className="flex items-center space-x-2">
          <button className="px-3 py-1 bg-gray-200 text-black rounded-lg">Préc.</button>
          {[1, 2, 3, 4, 5, 6].map((page) => (
            <button key={page} className={`px-3 py-1 ${page === 1 ? 'bg-red-600 text-white' : 'bg-gray-200 text-black'} rounded-lg`}>
              {page}
            </button>
          ))}
          <button className="px-3 py-1 bg-gray-200 text-black rounded-lg">Suiv.</button>
        </div>
      </div>

      {/* Modal pour ajouter une catégorie */}
      {showModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md relative">
            <h2 className="text-xl font-semibold mb-4">Ajouter une catégorie</h2>
            <form>
              <div className="mb-4">
                <label className="block text-gray-700">Code</label>
                <input type="text" className="w-full px-4 py-2 border rounded-lg" />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Intitulé</label>
                <input type="text" className="w-full px-4 py-2 border rounded-lg" />
              </div>
              <div className="flex justify-between">
                <button
                  type="button"
                  className="px-4 py-2 border rounded-lg text-red-600 border-red-600 hover:bg-red-50"
                  onClick={() => setShowModal(false)} // Fermer le modal
                >
                  Annuler
                </button>
                <button type="submit" className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700">
                  Valider
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default CategoryList;
