import React from 'react';
import { FaMapMarkerAlt, FaClock } from 'react-icons/fa';
import bannerbg from '../../assets/img/breadcrumbs/2.jpg';

import { Helmet } from 'react-helmet';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer/Footer';
import OffWrap from '../../components/Layout/Header/OffWrap';
import SearchModal from '../../components/Layout/Header/SearchModal';
import ScrollToTop from '../../components/Common/ScrollTop';

import favIcon from '../../assets/img/fav-orange.png';
import Logo from '../../assets/img/logo/dark-logo.png';
import footerLogo from '../../assets/img/logo/lite-logo.png';

const EventDetails = () => {
  return (
    <React.Fragment>
      {/* Helmet to manage favicon */}
      <Helmet>
        <link rel="icon" href={favIcon} />
      </Helmet>

      {/* Off-canvas and header */}
      <OffWrap />
      <Header
        parentMenu=""
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
        CanvasLogo={Logo}
        mobileNormalLogo={Logo}
        CanvasClass="right_menu_togle hidden-md"
        headerClass="full-width-header header-style1 home8-style4"
      />

<div className="font-sans">

{/* Banner Section */}
<div
  className="relative min-h-[90vh] sm:min-h-[80vh] lg:min-h-screen bg-cover bg-center"
  style={{ backgroundImage: `url(${bannerbg})` }}
>
  {/* Overlay */}
  <div className="absolute inset-0 bg-black/50"></div>

  {/* Content */}
  <div className="relative z-10 flex flex-col md:flex-row justify-between items-start md:items-center px-4 sm:px-8 md:px-20 py-10 h-full">
    {/* Left Side - Text */}
    <div className="text-white max-w-lg">
      <h1 className="text-3xl sm:text-5xl lg:text-6xl font-extrabold leading-tight tracking-wide text-white">
        Spicy Quince And <br /> Cranberry Chutney
      </h1>
      <p className="text-sm sm:text-lg lg:text-xl mt-4 sm:mt-6">
        Bootcamp Events Description Lorem ipsum dolor sit amet, consectetur
        adipiscing elit.
      </p>
      <div className="flex items-center text-xs sm:text-sm lg:text-base mt-4 sm:mt-6">
        <FaClock className="mr-2 text-white" />
        <span className="text-white">Heure et date</span>
      </div>
    </div>

    {/* Right Side - Participation Box */}
    <div className="mt-6 sm:mt-8 bg-white shadow-lg rounded-lg p-4 sm:p-6 md:p-8 w-full max-w-xs sm:max-w-sm mb-8 lg:mb-0">
      <h3 className="text-base sm:text-lg lg:text-xl font-bold text-gray-800">Participation</h3>
      <p className="text-xs sm:text-sm lg:text-base text-gray-600 mt-2 sm:mt-4">
        Lorem ipsum dolor sit amet consectetur. Condimentum egestas blandit
        interdum dignissim consectetur.
      </p>
      <button
        className="mt-4 sm:mt-6 bg-[#9C1623] text-white text-sm font-semibold py-2 rounded-md w-full transition-all hover:bg-[#81121C]"
      >
        Participer
      </button>
      <button
        className="mt-4 border border-[#9C1623] text-[#9C1623] text-sm font-semibold py-2 rounded-md w-full transition-all hover:bg-[#9C1623] hover:text-white"
      >
        Rejeter
      </button>
    </div>
  </div>
</div>



        {/* Details Section */}
        <div style={{ maxWidth: '75%', width: '100%' }} className="container mx-auto px-4 py-8 grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Description */}
          <div>
            <h2 className="text-2xl font-bold mb-4">Description</h2>
            <p className="text-gray-700">
              Lorem ipsum dolor sit amet consectetur. Condimentum egestas blandit interdum dignissim consectetur.
              Gravida ultricies elementum sed nulla magna lobortis quis.
            </p>
            <p className="text-gray-700 mt-4">
              Ut arcu magna senectus convallis dis. Ipsum arcu odio amet ipsum sed tellus eget. Pellentesque euismod.
            </p>
          </div>

          {/* Address */}
          <div>
            <h2 className="text-2xl font-bold mb-4">Adresse</h2>
            <div className="bg-gray-50 p-4 rounded-lg">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.835434509567!2d144.96305761531688!3d-37.81410797975159!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf577597a624801e7!2sFederation%20Square%2C%20Melbourne%20VIC%203000%2C%20Australia!5e0!3m2!1sen!2sus!4v1633715373190!5m2!1sen!2sus"
                width="100%"
                height="200"
                allowFullScreen=""
                loading="lazy"
                className="rounded-lg"
                title="Google Maps"
              ></iframe>
            </div>
          </div>

          {/* Hours */}
          <div>
            <h2 className="text-2xl font-bold mb-4">Horaires</h2>
            <p className="text-gray-700">
              Lorem ipsum dolor sit amet consectetur. Condimentum egestas blandit interdum dignissim consectetur.
            </p>
            <ul className="text-gray-700 mt-4">
              <li>- Lundi : 10h00 - 18h00</li>
              <li>- Mardi : 10h00 - 18h00</li>
              <li>- Mercredi : 10h00 - 18h00</li>
              <li>- Jeudi : 10h00 - 18h00</li>
              <li>- Vendredi : 10h00 - 18h00</li>
            </ul>
          </div>
        </div>

        {/* Upcoming Events Section */}
        <div style={{ maxWidth: '75%', width: '100%' }} className="container mx-auto px-4 py-8">
          <h2 className="text-3xl font-bold mb-8">Événements à venir</h2>
          {/* Replace with Event Component */}
        </div>
      </div>

      {/* Footer */}
      <Footer footerClass="rs-footer home9-style main-home" footerLogo={footerLogo} />
      <SearchModal />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default EventDetails;
