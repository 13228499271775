import React, { useState } from 'react';
import author1 from '../../assets/img/testimonial/test_1.png';
import SectionTitle from '../../components/Common/SectionTitle';

// Component for a single testimonial card
const TestimonialCard = ({ authorImage, name, description, rating }) => {
  const renderStars = (rating) => {
    return Array(5)
      .fill(0)
      .map((_, index) => (
        <svg
          key={index}
          className={`w-7 h-7 ${index < rating ? 'text-orange-400' : 'text-gray-300'}`}
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z" />
        </svg>
      ));
  };

  return (
    <div className="bg-white p-4 shadow-md border border-gray-200 flex flex-col items-start gap-4">
      {/* Top: Stars at the top right */}
      <div className="flex justify-end w-full">
        <div className="flex gap-1">{renderStars(rating)}</div>
      </div>

      {/* Profile image and name */}
      <div className="flex flex-col items-start">
        <img
          src={authorImage}
          alt={name}
          className="w-[76.78px] h-[76.78px] object-cover border border-gray-200 mb-3"
        />

      <h3
        style={{
          fontSize: '18px',
          fontWeight: 600,
          lineHeight: '26.1px',
          color: '#101012',
        }}
        className="mb-2"
      >
        {name}
      </h3>

      </div>

      {/* Testimonial text */}
      <p
          style={{
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '23.2px',
            color: '#101012',
          }}
          
        >
          {description}
        </p>

    </div>
  );
};

const Testimonials = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const testimonials = [
    {
      name: 'Sophie L.',
      description:
        'Une expérience d\'apprentissage exceptionnelle ! Les cours sont bien structurés, les instructeurs sont experts et le support étudiant est réactif. Je recommande vivement.',
      rating: 5,
      authorImage: author1,
    },
    {
      name: 'Thomas G.',
      description:
        'La qualité des cours en Data Science est impressionnante. Des projets pratiques et des ressources approfondies ont considérablement renforcé mes compétences.',
      rating: 4,
      authorImage: author1,
    },
    {
      name: 'Eva R.',
      description:
        'La variété des cours de marketing digital est incroyable. J\'ai acquis des compétences pratiques qui ont eu un impact immédiat sur ma carrière.',
      rating: 5,
      authorImage: author1,
    },
    {
      name: 'Alexandre B.',
      description:
        'La plateforme offre des outils pédagogiques exceptionnels. En tant qu\'enseignant, cela a simplifié la création de cours en ligne et amélioré l\'engagement des étudiants.',
      rating: 4,
      authorImage: author1,
    },
    {
      name: 'Camille M.',
      description:
        'Parfait pour une reconversion professionnelle ! Les cours flexibles m\'ont permis de concilier famille et apprentissage. Merci pour cette opportunité.',
      rating: 5,
      authorImage: author1,
    },
    {
      name: 'Nicolas D.',
      description:
        'Les cours de branding ont été un tournant pour mon entreprise. J\'ai pu appliquer immédiatement les stratégies acquises et voir des résultats positifs.',
      rating: 4,
      authorImage: author1,
    },
    {
      name: 'Amina S.',
      description:
        'Les cours de psychologie sont captivants. La plateforme offre une immersion profonde dans le domaine, et les instructeurs sont passionnants.',
      rating: 5,
      authorImage: author1,
    },
    {
      name: 'Lucie P.',
      description:
        'L\'accès aux projets pratiques et aux jeux de données réels m\'a vraiment aidée à maîtriser les compétences en sciences des données. Des cours de grande qualité.',
      rating: 5,
      authorImage: author1,
    },
    {
      name: 'Paul R.',
      description:
        'Les cours sur les énergies renouvelables étaient exactement ce dont j\'avais besoin pour développer mon entreprise. Un excellent investissement.',
      rating: 4,
      authorImage: author1,
    },
    {
      name: 'Marie C.',
      description:
        'La formation pour enseigner en ligne est exceptionnelle. J\'ai appris à créer des cours interactifs et à engager mes étudiants de manière innovante.',
      rating: 5,
      authorImage: author1,
    },
  ];
  
  const displayedTestimonials = testimonials.slice(activeIndex * 2, activeIndex * 2 + 2);

  const totalDots = 3;

  return (
    <section className="py-16 px-4 sm:px-6 lg:px-8 mx-auto w-[80%]">
      <SectionTitle
        sectionClass="sec-title"
        subtitleClass="sub-title"
        subtitle={<div>TÉMOIGNAGES</div>}
        titleClass="title"
        title={
          <div
            style={{
              fontSize: '28px',
              fontWeight: '700',
              lineHeight: '33.6px',
              letterSpacing: '-2px',
            }}
          >
            Ce que disent <br />
            nos étudiants...
          </div>
        }
      />

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {displayedTestimonials.map((testimonial, index) => (
          <TestimonialCard key={index} {...testimonial} />
        ))}
      </div>

      <div className="flex justify-center gap-2 mt-40">
        {Array.from({ length: totalDots }).map((_, index) => (
          <button
            key={index}
            onClick={() => setActiveIndex(index)}
            className={`w-3 h-3 rounded-full ${
              index === activeIndex ? 'bg-[#9c1623]' : 'bg-[#9c1623] opacity-15'
            }`}
          />
        ))}
      </div>

    </section>
  );
};

export default Testimonials;
