import 'tailwindcss/tailwind.css';
import React, { useState } from 'react';

const UserList = () => {
  const [activeMenu, setActiveMenu] = useState(null);
  const [activeTab, setActiveTab] = useState('Tous');
  const [showModal, setShowModal] = useState(false); // État pour gérer l'affichage du modal

  const users = [
    { id: 1, name: 'Leslie Alexander', email: 'nevaeh.simmons@example.com', date: '24/08/2024', status: 'Administrateur' },
    { id: 2, name: 'Wade Warren', email: 'kenzi.lawson@example.com', date: '24/08/2024', status: 'Etudiant' },
    { id: 3, name: 'Arlene McCoy', email: 'debbie.baker@example.com', date: '24/08/2024', status: 'Enseignant' },
    { id: 4, name: 'Jerome Bell', email: 'sara.cruz@example.com', date: '24/08/2024', status: 'Enseignant' },
    { id: 5, name: 'Ronald Richards', email: 'bill.sanders@example.com', date: '24/08/2024', status: 'Etudiant' },
    { id: 6, name: 'Dianne Russell', email: 'willie.jennings@example.com', date: '24/08/2024', status: 'Etudiant' },
    { id: 7, name: 'Kathryn Murphy', email: 'michael.mitc@example.com', date: '24/08/2024', status: 'Etudiant' },
    { id: 8, name: 'Courtney Henry', email: 'tim.jennings@example.com', date: '24/08/2024', status: 'Enseignant' },
    { id: 9, name: 'Jenny Wilson', email: 'felicia.reid@example.com', date: '24/08/2024', status: 'Etudiant' },
    { id: 10, name: 'Eleanor Pena', email: 'debra.holt@example.com', date: '24/08/2024', status: 'Enseignant' },
  ];

  const tabs = ['Tous', 'Etudiants', 'Enseignants', 'Administrateurs'];
  const filteredUsers = activeTab === 'Tous' ? users : users.filter((user) => user.status === activeTab);

  const handleMenuClick = (id) => {
    setActiveMenu(activeMenu === id ? null : id);
  };

  return (
    <div className="container mx-auto my-8">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold">Liste des utilisateurs</h2>
        <button
          className="border border-red-600 text-red-600 px-4 py-2 rounded-lg hover:bg-red-50"
          onClick={() => setShowModal(true)} // Ouvre le modal au clic
        >
          Ajouter un utilisateur
        </button>
      </div>

      {/* Onglets de filtre */}
      <div className="mb-4">
        <div className="flex space-x-4 border-b">
          {tabs.map((tab) => (
            <button
              key={tab}
              className={`py-2 px-4 ${activeTab === tab ? 'border-b-2 border-red-600 text-red-600' : 'text-gray-600'} focus:outline-none`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>

      {/* Table d'utilisateurs */}
      <div className="overflow-y-auto max-h-96">
        <table className="min-w-full bg-white border">
          <thead>
            <tr className="w-full bg-gray-100 text-left">
              <th className="p-4">#</th>
              <th className="p-4">Prénom & Nom</th>
              <th className="p-4">Adresse mail</th>
              <th className="p-4">Date d'inscription</th>
              <th className="p-4">Statut</th>
              <th className="p-4">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user) => (
              <tr key={user.id} className="border-t">
                <td className="p-4">{user.id}</td>
                <td className="p-4">{user.name}</td>
                <td className="p-4">{user.email}</td>
                <td className="p-4">{user.date}</td>
                <td className="p-4">{user.status}</td>
                <td className="p-4">
                  <div className="relative">
                    <button className="text-gray-600 hover:text-black focus:outline-none" onClick={() => handleMenuClick(user.id)}>
                      ...
                    </button>
                    {activeMenu === user.id && (
                      <div className="absolute right-0 mt-2 w-48 bg-white border rounded-lg shadow-lg z-10">
                        <ul>
                          <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">Modifier</li>
                          <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">Supprimer</li>
                          <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">Désactiver</li>
                        </ul>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="flex justify-between items-center mt-4">
        <p>{filteredUsers.length} sur 500 utilisateurs</p>
        <div className="flex items-center space-x-2">
          <button className="px-3 py-1 bg-gray-200 text-black rounded-lg">Préc.</button>
          {[1, 2, 3, 4, 5, 6].map((page) => (
            <button key={page} className={`px-3 py-1 ${page === 1 ? 'bg-red-600 text-white' : 'bg-gray-200 text-black'} rounded-lg`}>
              {page}
            </button>
          ))}
          <button className="px-3 py-1 bg-gray-200 text-black rounded-lg">Suiv.</button>
        </div>
      </div>

      {/* Modal pour ajouter un utilisateur */}
      {showModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md relative">
            <h2 className="text-xl font-semibold mb-4">Ajouter un utilisateur</h2>
            <form>
              <div className="mb-4">
                <label className="block text-gray-700">Nom</label>
                <input type="text" className="w-full px-4 py-2 border rounded-lg" />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Prénom (s)</label>
                <input type="text" className="w-full px-4 py-2 border rounded-lg" />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Adresse mail</label>
                <input type="email" className="w-full px-4 py-2 border rounded-lg" />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Statut</label>
                <input type="text" className="w-full px-4 py-2 border rounded-lg" />
              </div>
              <div className="flex justify-between">
                <button
                  type="button"
                  className="px-4 py-2 border rounded-lg text-red-600 border-red-600 hover:bg-red-50"
                  onClick={() => setShowModal(false)}
                >
                  Annuler
                </button>
                <button type="submit" className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700">
                  Valider
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserList;
