import React, { useEffect } from 'react';
import styled from 'styled-components';
import { FaBook, FaFileInvoice, FaPlus } from 'react-icons/fa';
import CourseCard from './CourseCard';
import { useAuth } from 'contexts/AuthProvider';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { updateCheckoutStatus } from 'services/payment';

const ContentContainer = styled.div`
  flex: 1;
  padding: 20px;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const DashboardHeader = styled.header`
  margin-bottom: 20px;

 
`;

const UserGreeting = styled.h1`
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 10px;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ActionButton = styled.button`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #f5f5f5;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  color: #333;

  svg {
    margin-right: 10px;
  }

  &:hover {
    background-color: #e0e0e0;
  }

  @media (max-width: 768px) {
    padding: 8px 16px;
    font-size: 14px;
  }
`;


const Section = styled.section`
  margin-bottom: 20px;
`;

const SectionTitle = styled.h3`
  margin-bottom: 10px;
  color: #333;
`;

const CoursesGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;

  @media (max-width: 768px) {
    justify-content: center;
    gap: 10px;
  }

  @media (min-width: 769px) {
    justify-content: flex-start;
  }
`;



const ViewMoreButton = styled.button`
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #fff;
  border: 1px solid #333;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  color: #333;

  &:hover {
    background-color: #f5f5f5;
  }

  @media (max-width: 768px) {
    padding: 8px 16px;
    font-size: 14px;
  }
`;

const MainContentDashboard = () => {
  const {user} = useAuth();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const status = params.get('status');
  const trabnsactionId = params.get('id');
  const close = params.get('close');
  const id = params.get('id');

  const handleUpdateCheckoutStatus = async () => {
    try {
   return  await updateCheckoutStatus(trabnsactionId, status);
    } 
    catch (error) {
      console.error('Error updating checkout status:', error);
    }
  };

  useEffect(() => {

    if (status) {
      console.log(`Status: ${status}`);
      // Vous pouvez afficher une notification ou effectuer une action en fonction du statut
      if (status === 'pending') {
        const response =  handleUpdateCheckoutStatus( trabnsactionId, status);
        toast.info('Votre paiement est en attente.');
      } else if (status === 'approved') {
      const response =  handleUpdateCheckoutStatus( trabnsactionId, status);
        toast.success('Votre paiement a été réussi.');
      } else if (status === 'failed') {
        const response =  handleUpdateCheckoutStatus( trabnsactionId, status);
        toast.error('Votre paiement a échoué.');
      }
    }
  }, [location]);
  return (
    <>
      <ContentContainer>
        <DashboardHeader>
          <UserGreeting>Bonjour {user?.full_name}</UserGreeting>
          <ActionsContainer>
            <ActionButton>
              <FaBook /> Mes tutos
            </ActionButton>
            <ActionButton>
              <FaFileInvoice /> Mes factures
            </ActionButton>
          </ActionsContainer>
        </DashboardHeader>
  
        <Section>
          <SectionTitle>Les Nouveautés</SectionTitle>
          <CoursesGrid>
            {[...Array(8)].map((_, index) => (
              <CourseCard
                key={index}
                title="Fitness Development Strategy Buildup Laoreet"
                price="45.99"
                category=""
                author="Gustave Ali COUDI"
                rating="4.5"
                students="245"
              />
            ))}
          </CoursesGrid>
          <ViewMoreButton>Voir plus de nouveautés</ViewMoreButton>
        </Section>
  
        <Section>
          <SectionTitle>Les cours en promo</SectionTitle>
          <CoursesGrid>
            {[...Array(3)].map((_, index) => (
              <CourseCard
                key={index}
                title="Fitness Development Strategy Buildup Laoreet"
                price="45.99"
                category=""
                author="Gustave Ali COUDI"
                rating="4.5"
                students="245"
              />
            ))}
          </CoursesGrid>
        </Section>
      </ContentContainer>
    </>
  );
};

export default MainContentDashboard;
