import React, { useState } from 'react';
import styled from 'styled-components';
import author1 from '../../assets/img/testimonial/style3/1.png';
import author2 from '../../assets/img/testimonial/style3/2.png';
import author3 from '../../assets/img/testimonial/style3/3.png';
import author4 from '../../assets/img/testimonial/style3/4.png';
import author5 from '../../assets/img/testimonial/style3/5.png';
import SectionTitle from '../../components/Common/SectionTitle';

const testimonials = [
  {
    id: 1,
    name: 'Sophie L.',
    title: 'Étudiante en Développement Web',
    text: 'Les cours sont bien structurés, et mon formateur s’est montré disponible. Je recommande vivement',
    img: author1,
  },
  {
    id: 2,
    name: 'Thomas G',
    title: 'Professionnel en Data Science',
    text: 'La qualité des cours en Data Science est assez impressionnante. Les projets pratiques effectués et les ressources approfondies ont considérablement renforcé mes compétences.',
    img: author2,
  },
  {
    id: 3,
    name: 'Eva R',
    title: 'Passionnée de Marketing Digital',
    text: "La variété des cours de marketing digital est intéressante. J'ai acquis des compétences pratiques qui m’ont aidée pour mon entretien d’embauche et mon CDD",
    img: author3,
  },
  {
    id: 4,
    name: 'Alexandre B',
    title: 'Enseignant en Ligne',
    text: "La plateforme offre des outils pédagogiques exceptionnels. En tant qu'enseignant, cela a simplifié la création de cours en ligne et amélioré l'engagement des étudiants",
    img: author4,
  },
  {
    id: 5,
    name: 'Camille M.',
    title: 'en Reconversion',
    text: 'Parfait pour une reconversion professionnelle ! J’ai l’embarras de choix. Merci pour cette opportunité',
    img: author5,
  },

  {
    id: 6,
    name: 'Nicolas D',
    title: 'Entrepreneur en Marketing',
    text: "J'ai pu appliquer immédiatement les stratégies acquises dans le cours sur le branding et voir des résultats positifs.",
    img: author2,
  },
  {
    id: 7,
    name: 'Lucie P',
    title: 'Étudiante en Sciences des Données',
    text: "L'accès aux projets pratiques et aux jeux de données réels m'a vraiment aidée à maîtriser les compétences en sciences des données. Des cours de grande qualité et à moindre coût",
    img: author3,
  },
  {
    id: 8,
    name: 'Paul R.',
    title: 'Entrepreneur en Énergie Renouvelable',
    text: "Ces cours sur les énergies renouvelables étaient exactement ce dont j'avais besoin pour développer mon entreprise. Un excellent investissement",
    img: author4,
  },
];

const Section = styled.section`
  text-align: left;
  padding: 40px 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  max-width: 80%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    max-width: 95%;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
  }
`;

const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

const Card = styled.div`
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 10px;
  border-radius: 8px;
  width: ${({ remainingCards }) =>
    remainingCards === 1 ? '100%' : remainingCards === 2 ? 'calc(50% - 20px)' : 'calc(33.33% - 20px)'};

  @media (max-width: 1024px) {
    width: calc(50% - 20px);
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Image = styled.img`
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-right: 20px;
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const NameTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Name = styled.h3`
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 26.1px;
  color: #101012;
`;

const TitleText = styled.h4`
  color: #26272B;
  font-weight: 400;
  margin: 0;
  font-size: 14px;
  line-height: 19.88px;
  margin-top: 7px;
`;

const Text = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 23.2px;
  color: #101012;

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const NavButtons = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    margin-top: 10px;
    justify-content: space-between;
    width: 100%;
  }
`;

const Button = styled.button`
  background-color: #fff;
  color: #c1272d;
  border: 2px solid #c1272d;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  width: 50px;
  height: 50px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;

  &:hover {
    background-color: #c1272d;
    color: #fff;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
    font-size: 16px;
  }
`;

const DotsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

const Dot = styled.span`
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: ${(props) => (props.active ? '#AB1827' : '#ddd')};
  border-radius: 50%;
  margin: 0 5px;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
`;

const TestimonialCard = ({ name, title, text, img, remainingCards }) => (
  <Card remainingCards={remainingCards}>
    <CardHeader>
      <Image src={img} alt={`${name}`} />
      <NameTitleContainer>
        <Name>{name}</Name>
        <TitleText>{title}</TitleText>
      </NameTitleContainer>
    </CardHeader>
    <Text>{text}</Text>
  </Card>
);

const Testimonials = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const cardsPerPage = 3;

  const handleNext = () => {
    if (currentPage < Math.ceil(testimonials.length / cardsPerPage) - 1) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleDotClick = (pageIndex) => {
    if (pageIndex !== currentPage) {
      setCurrentPage(pageIndex);
    }
  };

  const totalPages = Math.ceil(testimonials.length / cardsPerPage);
  const startIndex = currentPage * cardsPerPage;
  const visibleTestimonials = testimonials.slice(startIndex, startIndex + cardsPerPage);
  const remainingCards = visibleTestimonials.length;

  return (
    <Section>
      <Container>
        <HeaderContainer>
          <SectionTitle
            sectionClass="sec-title"
            subtitleClass="sub-title"
            subtitle={<div>TEMOIGNAGES</div>}
            titleClass="title"
            title={<div style={{ fontSize: '28px', fontWeight: '700', lineHeight: '33.6px', letterSpacing: '-2px' }}>Ce que disent <br />nos étudiants...</div>}
          />
          <NavButtons>
            <Button onClick={handlePrev} disabled={currentPage === 0}>&#8592;</Button>
            <Button onClick={handleNext} disabled={currentPage === totalPages - 1}>&#8594;</Button>
          </NavButtons>
        </HeaderContainer>
        <CardsWrapper>
          {visibleTestimonials.map((testimonial) => (
            <TestimonialCard key={testimonial.id} {...testimonial} remainingCards={remainingCards} />
          ))}
        </CardsWrapper>
        <DotsContainer>
          {Array.from({ length: totalPages }).map((_, pageIndex) => (
            <Dot key={pageIndex} active={pageIndex === currentPage} onClick={() => handleDotClick(pageIndex)} />
          ))}
        </DotsContainer>
      </Container>
    </Section>
  );
};

export default Testimonials;