import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import CourseCard from './CourseCard';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'; 
import { getAllCourseForAllUser } from 'services/course';
import { useParams } from 'react-router-dom';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  margin-top: 50px;

  @media (max-width: 768px) {
    padding: 0 15px;
  }
`;

const TitleSection = styled.div`
  margin-bottom: 50px; /* Ajoute une marge en bas du titre */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

const Title = styled.h1`
  font-size: 2em;
  color: #333;
  margin: 0;

  @media (max-width: 480px) {
    font-size: 1.5em;
  }
`;

const FilterSection = styled.div`
  display: flex;
  align-items: stretch; /* Assure un alignement de hauteur */
  margin-bottom: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const FiltersWithTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 250px;
  padding-right: 20px;

  @media (max-width: 768px) {
    padding-right: 0;
    margin-bottom: 20px;
  }
`;

const Filters = styled.div`
  font-size: 0.9em;

  ul {
    list-style: none;
    padding: 0;
    margin: 0 0 20px 0;

    li {
      margin-bottom: 10px;

      h4 {
        font-size: 1.1em;
        color: #333;
        margin-bottom: 5px;
      }

      ul {
        list-style: none;
        padding: 0;

        li {
          margin-bottom: 5px;
          color: #555;
          cursor: pointer;
          transition: color 0.3s;

          &:hover {
            color: #AB1827;
          }
        }
      }
    }
  }
`;

const CoursesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  flex: 1;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const NavigationButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60px;
  height: 40px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: #555;
  }
`;

const Arrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  color: ${props => (props.disabled ? '#aaa' : '#fff')};

  &:hover {
    background-color: ${props => (props.disabled ? 'none' : '#444')};
  }
`;

const PageNumberButton = styled.button`
  padding: 8px 16px;
  font-size: 1rem;
  color: ${props => (props.selected ? '#fff' : '#333')};
  background-color: ${props => (props.selected ? '#AB1827' : '#fff')};
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #f8f9fa;
  }

  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
`;

const PageInfo = styled.span`
  font-size: 1rem;
  color: #333;

  @media (max-width: 480px) {
    display: none;
  }
`;

const EmptyMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Ajustez cette hauteur en fonction de votre design */
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
  color: #777;
  background-color: #f9f9f9;
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

function CourseCatalog() {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalElement, setTotalElement] = useState(0);
  const [page, setPage] = useState(1);
  useParams();
  const { id } = useParams();

  useEffect(() => {
    const data = {
      page,
      per_page: 15,
      category_id: id
    };
    getAllCourseForAllUser(data)
      .then(response => {
        console.log(response.data.data);
        setCourses(response.data.data.courses.data);
        setTotalElement(response.data.data.courses.total);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  }, [page]);

  const totalPages = Math.ceil(totalElement / 15);
  return (
    <PageContainer>
      <FilterSection>
        <FiltersWithTitle>
          <TitleSection>
            <Title>Tous nos cours</Title>
          </TitleSection>
          <Filters>
            <ul>
              <li>
                <h4>Business & Marketing</h4>
                <ul>
                  <li>Cours type 1</li>
                  <li>Cours type 2</li>
                  <li>Cours type 3</li>
                </ul>
              </li>
              <li>
                <h4>Titre catégorie 2</h4>
                <ul>
                  <li>Nom catégorie</li>
                  <li>Nom catégorie</li>
                  <li>Nom catégorie</li>
                </ul>
              </li>
              <li>
                <h4>Titre catégorie 3</h4>
                <ul>
                  <li>Nom catégorie</li>
                  <li>Nom catégorie</li>
                  <li>Nom catégorie</li>
                </ul>
              </li>
              <li>
                <h4>Titre catégorie 4</h4>
                <ul>
                  <li>Nom catégorie</li>
                  <li>Nom catégorie</li>
                  <li>Nom catégorie</li>
                </ul>
              </li>
            </ul>
          </Filters>
        </FiltersWithTitle>

        <div style={{ flex: 1 }}>
          <CoursesGrid>
            {/* check if course is  not null , if dispaly pas de cours */}
            {loading && <p>Chargement...</p>}
            {courses.length === 0 && !loading && (
              <EmptyMessage>Pas de cours pour cette catégorie</EmptyMessage>
            )}
            {courses.map((course) => (
              <CourseCard key={course.id} course={course} />
            ))}
           
          </CoursesGrid>

          <PaginationContainer>
            <div className="flex items-center justify-center  space-x-4 mt-20">
              <PageInfo>Page {page} sur {totalPages}</PageInfo>

              <nav className="inline-flex items-center space-x-2">
                {Array.from({ length: totalPages }, (_, index) => (
                  <PageNumberButton
                    key={index}
                    selected={page === index + 1}
                    onClick={() => setPage(index + 1)}
                  >
                    {index + 1}
                  </PageNumberButton>
                ))}

                <NavigationButton>
                  <Arrow
                    onClick={() => page > 1 && setPage(page - 1)}
                    disabled={page === 1}
                  >
                    <FaChevronLeft />
                  </Arrow>

                  <Arrow
                    onClick={() => page < totalPages && setPage(page + 1)}
                    disabled={page === totalPages}
                  >
                    <FaChevronRight />
                  </Arrow>
                </NavigationButton>
              </nav>
            </div>
          </PaginationContainer>
        </div>
      </FilterSection>
    </PageContainer>
  );
}

export default CourseCatalog;
