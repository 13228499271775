import React, { useEffect, useState } from 'react';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer/Footer';
import OffWrap from '../../components/Layout/Header/OffWrap';
import SearchModal from '../../components/Layout/Header/SearchModal';
import CourseCatalog from './CourseCatalog';

// Image
import Logo from '../../assets/img/logo/dark-logo.png';
import footerLogo from '../../assets/img/logo/lite-logo.png';
import { useParams } from 'react-router-dom';
import {  getCategorieCourses } from 'services/categorieCourses';


const CourseCategoryPage = () => {
    const { id } = useParams();
   

   
    return (
        <React.Fragment>
            <OffWrap />
            <Header
                parentMenu=''
                headerNormalLogo={Logo}
                headerStickyLogo={Logo}
                CanvasLogo={Logo}
                mobileNormalLogo={Logo}
                CanvasClass="right_menu_togle hidden-md"
                headerClass="full-width-header header-style1 home8-style4"
                TopBar='enable'
                TopBarClass="topbar-area home8-topbar"
            />

            {/* Course Main */}
            <CourseCatalog id={id} />
            {/* Course Main */}

            <Footer
                footerClass="rs-footer home9-style main-home"
                footerLogo={footerLogo}
            />
            <SearchModal />
        </React.Fragment>
    );
}

export default CourseCategoryPage;